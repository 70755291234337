import { useEffect, useState } from 'react';
import {
  Redirect,
} from "react-router-dom";
import { getQueryParam } from '../helpers/window';
import { API, graphqlOperation } from 'aws-amplify';
import { getSecretToken } from '../graphql/queries';

export function PublicPageLayout({children}) {
  const [isLoading, setIsLoading] = useState(true)
  const [isTokenValid, setIsTokenValid] = useState(false)
  useEffect(() => {
    const check = async () => {
      try {
        setIsLoading(true)
        const queryToken = getQueryParam('token')
        const invalidError = new Error('Invalid token')
        if (!queryToken) {
          throw invalidError
        }
        const res = await API.graphql(graphqlOperation(getSecretToken, { id: queryToken }))
        if (!res.data.getSecretToken) {
          throw invalidError
        }
        setIsTokenValid(true)
      } catch {
        setIsTokenValid(false)
      } finally {
        setIsLoading(false)
      }

    }
    check()
  }, [])
  if (!isLoading && !isTokenValid) {
    return <Redirect to="/" />
  }
  if (isLoading) {
    return 'Loading...'
  }

  return children
}