// we use this file for customized queries that will be rewritten by codegen

export const listLicenses = /* GraphQL */ `
  query ListLicenses(
    $filter: ModelLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        firstDate
        lastDate
        type
        mediaLicensing
        addOnServices
        numberOfScripts
        showID
        show {
          id
          userID
          title
          author
          age
          genre
          type
          synopsis
          musicLink
          maleParts
          nonBinaryParts
          isBacktrackingAllowed
          femaleParts
          ensembleParts
          instruments
          licenseLocation
          licenseType
          licenseID
          poster {
            bucket
            key
            region
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
