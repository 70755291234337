import { v4 as uuidv4 } from 'uuid'
import config from '../aws-exports'
import { continents, countries } from './data/countries'
import { types, genres, age, instruments } from './data/showSortingOptions'


const page1 = [
  {
    type: 'text',
    name: 'title',
    title: 'Show Title',
    value: '',
    required: true
  }, {
    type: 'text',
    name: 'author',
    title: 'Written By',
    value: '',
    required: true
  }, {
    type: 'dropdown',
    name: 'genre',
    title: 'Select a genre',
    value: '',
    required: true,
    options: genres
  }, {
    type: 'dropdown',
    name: 'age',
    title: 'Audience Age',
    value: '',
    required: true,
    options: age
  }, {
    type: 'file',
    name: 'poster',
    title: 'Upload a show poster image',
    value: null,
    file: null,
    fileType: 'image/*',
    required: true
  }, {
    type: 'radio',
    name: 'type',
    title: 'What type of show are you uploading?',
    value: '',
    required: true,
    options: types
  }, {
    type: 'textfield',
    name: 'synopsis',
    title: 'Synopsis',
    value: '',
    required: true,
    placeholder: 'Please detail a 300 characters synopsis'
  }, {
    type: 'file',
    name: 'script',
    title: 'Upload Script',
    value: null,
    fileType: 'application/pdf',
    required: true
  }, {
    type: 'file',
    name: 'score',
    title: 'Upload Score',
    value: null,
    fileType: 'application/pdf',
    required: true
  }, {
    type: 'file',
    name: 'pitch',
    title: 'Upload Pitch',
    value: null,
    fileType: 'application/pdf',
    required: true
  }, {
    type: 'text',
    name: 'musicLink',
    title: 'Spotify/Apple Music link',
    value: ''
  }, {
    type: 'number',
    name: 'maleParts',
    title: 'Number of male parts',
    value: 0
  }, {
    type: 'number',
    name: 'femaleParts',
    title: 'Number of female parts',
    value: 0
  }, {
    type: 'number',
    name: 'ensembleParts',
    title: 'Number of ensemble parts',
    value: 0
  }, {
    type: 'number',
    name: 'nonBinaryParts',
    title: 'Number of non-binary/gender non-specified parts',
    value: 0
  }, {
    type: 'multi-dropdown',
    name: 'instruments',
    title: 'Scored for instruments',
    value: [],
    options: instruments
  },
  {
    type: 'checkbox',
    name: 'isBacktrackingAllowed',
    title: 'Is a backing track available?',
    value: false
  }
]

const page2 = {
  name: 'license',
  title: 'Where should this license be available?',
  value: '',
  required: true,
  options: [
    {
      name: 'worldwide',
      title: 'Worldwide'
    }, {
      value: '',
      name: 'continent',
      title: 'By Continent',
      sublist: continents
    }, {
      value: '',
      name: 'country',
      title: 'By Country',
      sublist: countries
    }
  ],
  type: {
    text: 'What type of theater company should this license be available for?',
    required: true,
    options: [
      {
        name: 'Amateur',
        value: false
      }, {
        name: 'Professional',
        value: false
      }
    ]
  }
}
export const allowedFilesByType = {
  'TV Series': ['script', 'pitch'],
  'Musical': ['script', 'score'],
  'Film': ['script'],
  'Play': ['script'],
}
export function preventSubmit (form) {
  const typeIndex = form.findIndex(item => item.name === 'type')
  const typeValue = form[typeIndex].value
  const allowedFiles = allowedFilesByType[typeValue] || [];
  (['score', 'pitch']).forEach(fileName => {
    const fileIndex = form.findIndex(item => item.name === fileName)
    if (allowedFiles.includes(fileName)) {
      form[fileIndex].required = true
    } else {
      form[fileIndex].required = false
    }
  })

  for (let i = 0; i < form.length; i++) {
    if (form[i].required && !form[i].value) {
      return true
    }
  }
}

export function configureS3Object(item){
  const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
  } = config

  const key = uuidv4() + '---' +  item.value.replace(' ', '')

  return { bucket, key, region }

}

export { page1, page2 }