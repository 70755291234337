/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      role
      stripeID
      isRecurring
      expiredAt
      shows {
        items {
          id
          userID
          title
          author
          age
          genre
          type
          synopsis
          musicLink
          maleParts
          femaleParts
          nonBinaryParts
          ensembleParts
          isBacktrackingAllowed
          instruments
          licenseLocation
          licenseType
          licenseID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      licenses {
        items {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      role
      stripeID
      isRecurring
      expiredAt
      shows {
        items {
          id
          userID
          title
          author
          age
          genre
          type
          synopsis
          musicLink
          maleParts
          femaleParts
          nonBinaryParts
          ensembleParts
          isBacktrackingAllowed
          instruments
          licenseLocation
          licenseType
          licenseID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      licenses {
        items {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      role
      stripeID
      isRecurring
      expiredAt
      shows {
        items {
          id
          userID
          title
          author
          age
          genre
          type
          synopsis
          musicLink
          maleParts
          femaleParts
          nonBinaryParts
          ensembleParts
          isBacktrackingAllowed
          instruments
          licenseLocation
          licenseType
          licenseID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      licenses {
        items {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShow = /* GraphQL */ `
  mutation CreateShow(
    $input: CreateShowInput!
    $condition: ModelShowConditionInput
  ) {
    createShow(input: $input, condition: $condition) {
      id
      userID
      title
      author
      age
      genre
      type
      synopsis
      script {
        bucket
        key
        region
        __typename
      }
      score {
        bucket
        key
        region
        __typename
      }
      pitch {
        bucket
        key
        region
        __typename
      }
      poster {
        bucket
        key
        region
        __typename
      }
      musicLink
      maleParts
      femaleParts
      nonBinaryParts
      ensembleParts
      isBacktrackingAllowed
      instruments
      licenseLocation
      licenseType
      licenseID
      license {
        id
        userID
        firstDate
        lastDate
        type
        mediaLicensing
        addOnServices
        numberOfScripts
        showID
        show {
          id
          userID
          title
          author
          age
          genre
          type
          synopsis
          musicLink
          maleParts
          femaleParts
          nonBinaryParts
          ensembleParts
          isBacktrackingAllowed
          instruments
          licenseLocation
          licenseType
          licenseID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tokens {
        items {
          id
          showID
          expiredAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShow = /* GraphQL */ `
  mutation UpdateShow(
    $input: UpdateShowInput!
    $condition: ModelShowConditionInput
  ) {
    updateShow(input: $input, condition: $condition) {
      id
      userID
      title
      author
      age
      genre
      type
      synopsis
      script {
        bucket
        key
        region
        __typename
      }
      score {
        bucket
        key
        region
        __typename
      }
      pitch {
        bucket
        key
        region
        __typename
      }
      poster {
        bucket
        key
        region
        __typename
      }
      musicLink
      maleParts
      femaleParts
      nonBinaryParts
      ensembleParts
      isBacktrackingAllowed
      instruments
      licenseLocation
      licenseType
      licenseID
      license {
        id
        userID
        firstDate
        lastDate
        type
        mediaLicensing
        addOnServices
        numberOfScripts
        showID
        show {
          id
          userID
          title
          author
          age
          genre
          type
          synopsis
          musicLink
          maleParts
          femaleParts
          nonBinaryParts
          ensembleParts
          isBacktrackingAllowed
          instruments
          licenseLocation
          licenseType
          licenseID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tokens {
        items {
          id
          showID
          expiredAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShow = /* GraphQL */ `
  mutation DeleteShow(
    $input: DeleteShowInput!
    $condition: ModelShowConditionInput
  ) {
    deleteShow(input: $input, condition: $condition) {
      id
      userID
      title
      author
      age
      genre
      type
      synopsis
      script {
        bucket
        key
        region
        __typename
      }
      score {
        bucket
        key
        region
        __typename
      }
      pitch {
        bucket
        key
        region
        __typename
      }
      poster {
        bucket
        key
        region
        __typename
      }
      musicLink
      maleParts
      femaleParts
      nonBinaryParts
      ensembleParts
      isBacktrackingAllowed
      instruments
      licenseLocation
      licenseType
      licenseID
      license {
        id
        userID
        firstDate
        lastDate
        type
        mediaLicensing
        addOnServices
        numberOfScripts
        showID
        show {
          id
          userID
          title
          author
          age
          genre
          type
          synopsis
          musicLink
          maleParts
          femaleParts
          nonBinaryParts
          ensembleParts
          isBacktrackingAllowed
          instruments
          licenseLocation
          licenseType
          licenseID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tokens {
        items {
          id
          showID
          expiredAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLicense = /* GraphQL */ `
  mutation CreateLicense(
    $input: CreateLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    createLicense(input: $input, condition: $condition) {
      id
      userID
      firstDate
      lastDate
      type
      mediaLicensing
      addOnServices
      numberOfScripts
      showID
      show {
        id
        userID
        title
        author
        age
        genre
        type
        synopsis
        script {
          bucket
          key
          region
          __typename
        }
        score {
          bucket
          key
          region
          __typename
        }
        pitch {
          bucket
          key
          region
          __typename
        }
        poster {
          bucket
          key
          region
          __typename
        }
        musicLink
        maleParts
        femaleParts
        nonBinaryParts
        ensembleParts
        isBacktrackingAllowed
        instruments
        licenseLocation
        licenseType
        licenseID
        license {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        tokens {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLicense = /* GraphQL */ `
  mutation UpdateLicense(
    $input: UpdateLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    updateLicense(input: $input, condition: $condition) {
      id
      userID
      firstDate
      lastDate
      type
      mediaLicensing
      addOnServices
      numberOfScripts
      showID
      show {
        id
        userID
        title
        author
        age
        genre
        type
        synopsis
        script {
          bucket
          key
          region
          __typename
        }
        score {
          bucket
          key
          region
          __typename
        }
        pitch {
          bucket
          key
          region
          __typename
        }
        poster {
          bucket
          key
          region
          __typename
        }
        musicLink
        maleParts
        femaleParts
        nonBinaryParts
        ensembleParts
        isBacktrackingAllowed
        instruments
        licenseLocation
        licenseType
        licenseID
        license {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        tokens {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLicense = /* GraphQL */ `
  mutation DeleteLicense(
    $input: DeleteLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    deleteLicense(input: $input, condition: $condition) {
      id
      userID
      firstDate
      lastDate
      type
      mediaLicensing
      addOnServices
      numberOfScripts
      showID
      show {
        id
        userID
        title
        author
        age
        genre
        type
        synopsis
        script {
          bucket
          key
          region
          __typename
        }
        score {
          bucket
          key
          region
          __typename
        }
        pitch {
          bucket
          key
          region
          __typename
        }
        poster {
          bucket
          key
          region
          __typename
        }
        musicLink
        maleParts
        femaleParts
        nonBinaryParts
        ensembleParts
        isBacktrackingAllowed
        instruments
        licenseLocation
        licenseType
        licenseID
        license {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        tokens {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSecretToken = /* GraphQL */ `
  mutation CreateSecretToken(
    $input: CreateSecretTokenInput!
    $condition: ModelSecretTokenConditionInput
  ) {
    createSecretToken(input: $input, condition: $condition) {
      id
      showID
      show {
        id
        userID
        title
        author
        age
        genre
        type
        synopsis
        script {
          bucket
          key
          region
          __typename
        }
        score {
          bucket
          key
          region
          __typename
        }
        pitch {
          bucket
          key
          region
          __typename
        }
        poster {
          bucket
          key
          region
          __typename
        }
        musicLink
        maleParts
        femaleParts
        nonBinaryParts
        ensembleParts
        isBacktrackingAllowed
        instruments
        licenseLocation
        licenseType
        licenseID
        license {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        tokens {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      expiredAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSecretToken = /* GraphQL */ `
  mutation UpdateSecretToken(
    $input: UpdateSecretTokenInput!
    $condition: ModelSecretTokenConditionInput
  ) {
    updateSecretToken(input: $input, condition: $condition) {
      id
      showID
      show {
        id
        userID
        title
        author
        age
        genre
        type
        synopsis
        script {
          bucket
          key
          region
          __typename
        }
        score {
          bucket
          key
          region
          __typename
        }
        pitch {
          bucket
          key
          region
          __typename
        }
        poster {
          bucket
          key
          region
          __typename
        }
        musicLink
        maleParts
        femaleParts
        nonBinaryParts
        ensembleParts
        isBacktrackingAllowed
        instruments
        licenseLocation
        licenseType
        licenseID
        license {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        tokens {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      expiredAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSecretToken = /* GraphQL */ `
  mutation DeleteSecretToken(
    $input: DeleteSecretTokenInput!
    $condition: ModelSecretTokenConditionInput
  ) {
    deleteSecretToken(input: $input, condition: $condition) {
      id
      showID
      show {
        id
        userID
        title
        author
        age
        genre
        type
        synopsis
        script {
          bucket
          key
          region
          __typename
        }
        score {
          bucket
          key
          region
          __typename
        }
        pitch {
          bucket
          key
          region
          __typename
        }
        poster {
          bucket
          key
          region
          __typename
        }
        musicLink
        maleParts
        femaleParts
        nonBinaryParts
        ensembleParts
        isBacktrackingAllowed
        instruments
        licenseLocation
        licenseType
        licenseID
        license {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        tokens {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      expiredAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
