import React from 'react'
import SyncIcon from '@material-ui/icons/Sync'
import { makeStyles } from '@material-ui/core'


const useStyles = makeStyles({
  '@global': {'@keyframes rotating': {
    '0%': {
      webkitTransform: 'rotate(360deg)',
      transform: 'rotate(360deg)'
    },
    '100%': {
      webkitTransform: 'rotate(0deg)',
      transform: 'rotate(0deg)'
    }
  }},
  uploadingIcon: {
    animation: '$rotating 2s linear infinite',
    fontSize: size => size
  },
  wrapper: {
    width: '100%',
    height: 300
  },
  spinner: {
    width: size => size,
    transform: 'scaleX(-1)',
    margin: '260px auto 0'
  }
})

export default function Loader ({ size = 60 }) {
  const c = useStyles(size)

  return (
    <div className={c.wrapper}>
      <div className={c.spinner}>
        <SyncIcon className={c.uploadingIcon}/>
      </div>
    </div>
  )
}