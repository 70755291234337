const prices = {
  Professional: {
    'Film': 2000,
    'TV Series': 2000,
    'Musical': 800,
    'Play': 130
  },
  Amateur: {
    'Film': 300,
    'TV Series': 300,
    'Musical': 590,
    'Play': 69
  },
  addOnServices: {
    'Set Design': 1000,
    'Video Recording': 1000,
    'Marketing Support': 1000,
    'Costume Production': 1000
  },
  royalty: 0.8,
  mediaLicensing: 150,
  script: 7.99
}


export default function priceCalculation({
  showType,
  licenseType,
  addOnServices,
  mediaLicensing,
  isTheaterProduction,
  tickets,
  numberOfScripts
}){
  const _royaltySubtotal = tickets.performances * tickets.venueCapacity * tickets.price * prices.royalty
  const basePrice = licenseType ? prices[licenseType][showType] : 0
  const addOnServicesPrice = addOnServices.reduce((totalPrice, service) => prices.addOnServices[service] + totalPrice, 0)
  const mediaLicensingPrice = mediaLicensing ? prices.mediaLicensing : 0
  const scriptsPrice = numberOfScripts * prices.script

  const _totalPrice = basePrice + addOnServicesPrice + mediaLicensingPrice + _royaltySubtotal + scriptsPrice
  
  const totalPrice = parseFloat(_totalPrice).toFixed(2)
  const royaltySubtotal = parseFloat(_royaltySubtotal).toFixed(2)

  return {
    royaltySubtotal,
    totalPrice,
    basePrice,
    addOnServicesPrice,
    mediaLicensingPrice,
    scriptsPrice
  }
}
