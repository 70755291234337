import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import { createSecretToken, deleteSecretToken } from "../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { CopyableInput } from "./CopyableInput";
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  field: {
    width: "100%",
  },
  listContent: {
    width: "100%",
  },
  button: {
    marginRight: "1em",
  },
  addButton: {
    backgroundColor: green[500],
  }
}));
function LinkItem({ token, onDelete, ...rest }) {
  const c = useStyles();
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await API.graphql(
        graphqlOperation(deleteSecretToken, {
          input: {
            id: token.id,
          },
        })
        );
        onDelete(token);
    } finally {
      setIsDeleting(false);
      setIsDeleteConfirmationOpen(false);
    }
  };
  const generateLink = (token) => {
    return `${window.location.origin}/p/show/${token.showID}/script?token=${token.id}`;
  };
  const formatDate = (date) => {
    return moment(new Date(date)).format("MMMM Do YYYY");
  };

  return (
    <div {...rest}>
      <TextField
        readonly
        disabled
        value={generateLink(token)}
        className={c.field}
      />
      <Typography variant="caption">
        Valid until: {formatDate(token.expiredAt)}
      </Typography>
      <div>
        <CopyableInput value={generateLink(token)}>
          {({ copy, isCopied }) => (
            <Button
              variant="contained"
              color="secondary"
              className={c.button}
              onClick={copy}
            >
              {isCopied ? "Copied" : "Copy"}
            </Button>
          )}
        </CopyableInput>
        <Button
          variant="contained"
          color="default"
          onClick={() => setIsDeleteConfirmationOpen(true)}
        >
          Revoke
        </Button>
      </div>
      <DeleteConfirmationDialog
        open={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        isLoading={isDeleting}
        onConfirm={handleDelete}
      />
    </div>
  );
}

function DeleteConfirmationDialog({ open, isLoading, onConfirm, onClose }) {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Revoke Link?</DialogTitle>
      <DialogActions>
        <Button color="secondary" onClick={onConfirm} disabled={isLoading}>
          Confirm
        </Button>
        <Button color="default" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const MAX_TOKENS = 5;
export default function ShareScriptDialog({
  onClose,
  open,
  showId,
  tokens = [],
}) {
  const [localTokens, setLocalTokens] = useState(
    tokens.filter((token) => {
      return new Date(token.expiredAt) > new Date();
    })
  );
  const tokensRef = useRef([]);
  useEffect(() => {
    tokensRef.current = [...localTokens];
  }, [localTokens]);
  const [isGenerating, setIsGenerating] = useState(false);
  const generateToken = useCallback(async () => {
    if (tokensRef.current.length > MAX_TOKENS) {
      return;
    }
    setIsGenerating(true);
    try {
      const expiredAt = new Date();
      expiredAt.setDate(expiredAt.getDate() + 14);
      const res = await API.graphql({
        query: createSecretToken,
        variables: {
          input: {
            showID: showId,
            expiredAt,
          },
        },
      });
      if (res.data) {
        setLocalTokens((currentTokens) => [
          ...currentTokens,
          res.data.createSecretToken,
        ]);
      }
    } finally {
      setIsGenerating(false);
    }
  }, [showId]);
  const onDelete = (token) => {
    setLocalTokens((currentTokens) =>
      currentTokens.filter((cToken) => cToken !== token)
    );
  };
  const c = useStyles();

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Share Link</DialogTitle>
      <List sx={{ pt: 0 }}>
        {localTokens.map((token) => (
          <ListItem key={token.id}>
            <LinkItem
              className={c.listContent}
              token={token}
              onDelete={onDelete}
            />
          </ListItem>
        ))}
        <ListItem
          button
          disabled={isGenerating || localTokens.length > MAX_TOKENS}
          onClick={generateToken}
        >
          <ListItemAvatar>
            <Avatar className={c.addButton}>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Generate public link" />
        </ListItem>
      </List>
    </Dialog>
  );
}
