import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import StraightenIcon from '@material-ui/icons/Straighten'
import VoicemailIcon from '@material-ui/icons/Voicemail'
import { IoManSharp, IoPeopleSharp, IoWomanSharp } from 'react-icons/io5'
import MusicEmbed from '../../components/MusicEmbed'


const useStyles = makeStyles((theme) => ({
  essentialInformation: {
    display: 'flex',
    alignItems: 'start'
  },
  icons: {
    flex: 1,
    paddingRight: theme.spacing(3)
  },
  musicInfo: {
    display: 'flex',
    flex: 3,
    flexDirection: 'column'
  },
  instruments: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  imageIconContainer: {
    width: '28px',
    display: 'flex',
    justifyContent: 'center'
  },
  imageIcon: {
    width: '18px',
    maxWidth: '100%'
  },
  info: {
    marginLeft: theme.spacing(2),
    color: 'gray'
  }
}))

const iconSize = '28px'


export default function Info ({ show }) {

  const c = useStyles()

  const instrumentsLabel = (() => {
    if (show.instruments.length) {
      return show.instruments.join(', ')
    } else {
      return ''
    }
  })();
  return (
    <>
      <Grid item sm={12} md={6}>
        <Typography variant='body1'>
          {show.synopsis}
        </Typography>
      </Grid>

      <Grid item sm={12} md={6}>
        <Typography variant='h6'>
          Essential Information
        </Typography>

        <Box className={c.essentialInformation}>
          <Box className={c.icons}>
            <div className={c.instruments}>
              <IoManSharp size={iconSize}/>
              <span className={c.info}>{show.maleParts}</span>
            </div>
            <div className={c.instruments}>
              <IoWomanSharp size={iconSize}/>
              <span className={c.info}>{show.femaleParts}</span>
            </div>
            <div className={c.instruments}>
              <IoPeopleSharp size={iconSize}/>
              <span className={c.info}>{show.ensembleParts}</span>
            </div>
            <div className={c.instruments}>
              <div className={c.imageIconContainer}>
                <img className={c.imageIcon} src="/non-binaryLogo.png" alt="Non-binary person icon" />
              </div>
              <span className={c.info}>{show.nonBinaryParts || 0}</span>
            </div>
          </Box>

          <Box className={c.musicInfo}>
            {!!instrumentsLabel && <div className={c.instruments}>
              <StraightenIcon size={iconSize}/>
              <Typography variant='body1' className={c.info}>
                {instrumentsLabel}
              </Typography>
            </div>}
            {!!show.isBacktrackingAllowed && <div className={c.instruments}>
              <VoicemailIcon size={iconSize}/>
              <Typography variant='body1' className={c.info}>
                Backing Track available
              </Typography>
            </div>}
          </Box>
        </Box>
      </Grid>

      {show.musicLink && <Grid item xs={12}>
        <MusicEmbed link={show.musicLink}/>
      </Grid>}
    </>
  )
}