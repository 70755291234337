import { API, graphqlOperation, Storage } from 'aws-amplify'
import { useState, useEffect } from 'react'
import { getShow } from '../../graphql/queries'


export default function useGetShow (id, poster) {
  const [show, setShow] = useState(null)
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    id && getScriptInfo(id)

    async function getScriptInfo (id) {
      try {
        const res = await API.graphql(graphqlOperation(getShow, { id }))
        const _show = res.data.getShow

        if (poster) {
          _show.posterURL = await Storage.get(_show.poster.key)
        }

        setShow(_show)
        setPageLoading(false)
      }

      catch (error) {
        console.log(error)
      }
    }
  }, [id])

  return { show, pageLoading }

}