import React from "react";
import { User } from "../helpers/contexts";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Landing from "../pages/Landing";
import Layout from "./Layout";
import AuthPage from "../pages/Auth";
import UserSettings from "../pages/UserSettings";
import Contact from "../pages/Contact";
import AllShows from "../pages/AllShows";
import ShowForm1 from "../pages/ShowForm1";
import ShowForm2 from "../pages/ShowForm2";
import Show from "../pages/Show";
import MyList from "../pages/MyList";
import { isSubscribed } from "../helpers/subscription";
import OrderSuccessProcessingPage from "../pages/OrderSuccessProcessingPage";
import { getUserFunctionalRole } from "../helpers/users";
import { PublicPageLayout } from './PublicPageLayout';

const sharedPages = [
  {
    path: "/account",
    component: <UserSettings />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
];

const privatePages = {
  writer: [
    ...sharedPages,
    {
      path: "/home",
      component: <MyList uploads />,
    },
    {
      path: "/upload",
      component: <ShowForm1 />,
    },
    {
      path: "/license",
      component: <ShowForm2 />,
    },
    {
      path: "/show/:id/edit",
      component: <ShowForm1 update />,
    },
  ],
  producer: [
    ...sharedPages,
    {
      path: "/home",
      component: <AllShows />,
    },
    {
      path: "/licenses",
      component: <MyList licenses />,
    },
    {
      path: "/show/:id",
      component: <Show info />,
    },
    {
      path: "/show/:id/get-license",
      component: <Show getLicense />,
    },
    {
      path: "/show/:id/view-license",
      component: <Show viewLicense />,
    },
    {
      path: "/show/:id/script",
      component: <Show script />,
    },
    {
      path: "/show/:id/score",
      component: <Show score />,
    },
    {
      path: "/show/:id/pitch",
      component: <Show pitch />,
    },
  ],
};

export default function Routes() {
  const { user } = React.useContext(User);
  const isUserActive = isSubscribed(user);
  const userFunctionalRole = getUserFunctionalRole(user);
  return (
    <Router>
      <Switch>
        {isUserActive &&
          privatePages[userFunctionalRole].map((page) => (
            <Route exact path={page.path} key={page.path}>
              <Layout>{page.component}</Layout>
            </Route>
          ))}
        <Route path="/login">
          {isSubscribed(user) ? (
            <Redirect to="/home" />
          ) : (
            <Landing>
              <AuthPage />
            </Landing>
          )}
        </Route>
        <Route path="/signup">
          {user ? (
            <Redirect to="/home" />
          ) : (
            <Landing>
              <AuthPage newUser />
            </Landing>
          )}
        </Route>
        <Route path="/forgot-password">
          {user ? (
            <Redirect to="/home" />
          ) : (
            <Landing>
              <AuthPage forgotPassword />
            </Landing>
          )}
        </Route>
        <Route path="/order-success">
          <OrderSuccessProcessingPage />
        </Route>
        <Route path="/p/show/:id/script">
          <Layout>
            <PublicPageLayout>
              <Show script isPublic />
            </PublicPageLayout>
          </Layout>
        </Route>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route path="*">
          {user ? <h1>NOT FOUND</h1> : <Redirect to="/" />}
        </Route>
      </Switch>
    </Router>
  );
}
