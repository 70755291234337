import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Response from '../components/Response'
import { UploadForm, User } from '../helpers/contexts'
import { API, Storage } from 'aws-amplify'
import { createShow } from '../graphql/mutations'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { preventSubmit, configureS3Object } from '../helpers/uploadForm'
import WaitOverlay from '../components/WaitOverlay'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'


const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex'
  },
  form: {
    marginTop: theme.spacing(1),
    flex: 1
  },
  headerText: {
    marginTop: theme.spacing(1)
  },
  formItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  fieldset: {
    display: 'block',
    marginTop: theme.spacing(4)
  },
  dropdown: {
    width: '100%'
  },
  backButton: {
    marginTop: theme.spacing(2),
    width: 200
  },
  saveButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: 200
  },
  poster: {
    flex: '0 0 300px',
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  alert: {
    height: 'fit-content',
    alignSelf: 'flex-end',
    margin: theme.spacing(3)
  }
}))


export default function ShowForm2 () {
  const [uploading, setUploading] = useState(false)
  const [response, setResponse] = useState(null)

  const {
    uploadPage1: showForm,
    uploadPage2: licenseForm,
    setUploadPage2: setLicenseForm
  } = React.useContext(UploadForm)
  const { user } = React.useContext(User)
  const c = useStyles()
  const history = useHistory()


  function handleChange (event) {
    const name = event.target.name
    const value = event.target.value

    if (name === 'license') {
      setLicenseForm({
        ...licenseForm,
        value
      })
    } else {
      const _options = licenseForm.options.map(option => {
        if (option.name === name) {
          return {
            ...option,
            value
          }
        }

        return option
      })

      setLicenseForm({
        ...licenseForm,
        options: _options
      })
    }
  }

  function handleTypeChange (event) {
    const name = event.target.name
    const checked = event.target.checked

    const _options = licenseForm.type.options.map(option => {
      if (name === option.name) {
        return {
          ...option,
          value: checked
        }
      }

      return option
    })

    setLicenseForm({
      ...licenseForm,
      type: {
        ...licenseForm.type,
        options: _options
      }
    })
  }

  async function uploadShow () {

    setUploading(true)
    const input = {
      userID: user.id
    }

    showForm.forEach(item => {
      if (item.type === 'file') {

        if (!item.value) {
          setUploading(false)
          setResponse({
            type: 'error',
            text: 'File missing: ' + item.title
          })
          return
        }

        const { bucket, key, region } = configureS3Object(item)
        Storage.put(key, item.file, { level: 'public' })
          .catch(console.log)
        input[item.name] = { bucket, key, region }
      } else {
        input[item.name] = item.value
      }
    })

    input.licenseLocation = licenseForm.value
    const _licenseType = licenseForm.type.options.filter(option => {
      return option.value
    })

    input.licenseType = _licenseType.map(option => option.name)
    try {
      await API.graphql({
        query: createShow,
        variables: { input }
      })

      setResponse({
        type: 'success',
        text: 'Submitted Successfully'
      })
    }

    catch (error) {
      setResponse({
        type: 'error',
        text: error.errors[0].message
      })
    }

    finally {
      setUploading(false)
    }
  }

  function preventShowSubmit (){
    preventSubmit(showForm)

    if (!licenseForm.value) return true

    const noTypeValue = licenseForm.type.options.every(option => option.value === false)
    if (noTypeValue) return true

    return false
  }

  const title = showForm.find(formItems => formItems.name === 'title').value
  const author = showForm.find(formItems => formItems.name === 'author').value
  const poster = showForm.find(formItems => formItems.name === 'poster').file
  let posterImg

  if (poster) posterImg = URL.createObjectURL(poster)


  return <>
    <WaitOverlay loading={uploading}/>

    <Typography variant='h4'>Set Additional License Rules</Typography>
    <Typography variant='body1' className={c.headerText}>
      You can set additional license rules for this show using the fields below.
    </Typography>
    <div className={c.wrapper}>
      <form className={c.form}>
        <FormControl
          required
          component='fieldset'
          className={clsx(c.formItem, c.fieldset)}
        >
          <FormLabel component='legend'>{licenseForm.title}</FormLabel>
          <RadioGroup
            name={licenseForm.name}
            value={licenseForm.value}
            onChange={handleChange}
          >
            {licenseForm.options.map(({ name, value, title, sublist }, index) =>
              <div key={index}>
                <FormControlLabel
                  value={name}
                  control={<Radio/>}
                  label={title}
                  labelPlacement='end'
                />
                {sublist && <FormControl
                  variant='outlined'
                  className={clsx(c.dropdown, c.formItem)}
                >
                  <Select
                    disabled={licenseForm.value !== name}
                    name={name}
                    value={value}
                    onChange={handleChange}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {sublist.map((worldLocation, index) =>
                      <MenuItem key={'inner' + index} value={worldLocation}>
                        {worldLocation}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>}
              </div>
            )}
          </RadioGroup>
        </FormControl>
        <FormControl
          required
          component='fieldset'
          className={clsx(c.fieldset, c.formItem)}
        >
          <FormLabel component='legend'>{licenseForm.type.text}</FormLabel>
          <FormGroup>
            {licenseForm.type.options.map(({ value, name }, index) =>
              <FormControlLabel
                key={index}
                control={<Checkbox checked={value} onChange={handleTypeChange} name={name}/>}
                label={name}
              />
            )}
          </FormGroup>
        </FormControl>
      </form>
      {posterImg && <div className={c.poster}>
        <GridList>
          <GridListTile cols={2} rows={2}>
            <img src={posterImg} alt={title}/>
            <GridListTileBar
              title={title}
              subtitle={<span>by: {author}</span>}
              actionIcon={
                <IconButton aria-label={`info about ${title}`} className={c.icon}>
                  <InfoIcon/>
                </IconButton>
              }
            />
          </GridListTile>
        </GridList>
      </div>}
    </div>
    <div className={c.buttons}>
      <Button
        variant='contained'
        size='large'
        className={c.backButton}
        component={Link}
        to='/upload'
      >
        Back
      </Button>
      <Button
        variant='contained'
        color='primary'
        size='large'
        className={c.saveButton}
        onClick={uploadShow}
        disabled={preventShowSubmit()}
      >
        Save
      </Button>
    </div>

    {response &&
      <Response
        response={response}
        className={c.alert}
        closeAlert={() => {
          setResponse(null)
          history.push('/home')
        }}
      />}
  </>
}