import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SyncIcon from '@material-ui/icons/Sync'


const useStyles = makeStyles((theme) => ({
  upload: uploading => ({
    visibility: (uploading) ? 'visible' : 'hidden',
    opacity: (uploading) ? 1 : 0,
    transition: '500ms all ease-in-out',
    position: 'fixed',
    textAlign: 'center',
    paddingTop: 300,
    backgroundColor: 'rgba(255,255,255,0.8)',
    zIndex: 3,
    inset: 0
  }),
  uploadingIcon: {
    animation: 'rotating 2s linear infinite',
    fontSize: 80
  },
  spinner: {
    margin: [theme.spacing(1), 'auto', 0]
  }
}))


export default function WaitOverlay({ loading }){
  const c = useStyles(loading)

  return (
    <div className={c.upload}>
      <Typography variant='h5'>
        Submitting
      </Typography>
      <div className={c.spinner}>
        <SyncIcon className={c.uploadingIcon}/>
      </div>
    </div>
  )
}