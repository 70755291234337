import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { API, Auth, graphqlOperation as operation } from 'aws-amplify'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Link as NavLink } from 'react-router-dom'
import { createUser } from '../graphql/mutations'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import SyncIcon from '@material-ui/icons/Sync'
import { getUser } from '../graphql/queries'
import { User } from '../helpers/contexts'
import Loader from '../components/Loader'
import StripePayment from '../components/StripePayment'
import { mergeProfiles } from '../helpers/users'
import { isSubscribed } from '../helpers/subscription'
import useAsyncRequest from '../helpers/customHooks/useAsyncRequest'

const useStyles = makeStyles(theme => ({
  uploadingIcon: {
    animation: 'rotating 2s linear infinite'
  },
  loaderIcon: {
    marginLeft: theme.spacing(1),
    lineHeight: 1,
    transform: 'scaleX(-1)'
  },
  container: {
    paddingTop: theme.spacing(4)
  },
  formControl: {
    minWidth: theme.spacing(16)
  },
  select: {
    width: '100%'
  },
  link: {
    display: 'block',
    width: '100%',
    textAlign: 'center'
  },
  buttons: {
    display: 'flex'
  },
  button: {
    margin: theme.spacing(2, 0, 1)
  },
  signupLinkButton: {
    margin: theme.spacing(0, 0, 10)
  },
  error: {
    color: theme.palette.error.main,
    textAlign: 'center',
    direction: 'ltr'
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  roleText: {
    display: 'flex',
    justifyContent: 'center'
  },
  role: {
    margin: theme.spacing(3, 0)
  },
  roleForm: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
}))


// It doesnt work yet, we have different stripe integration
const signupPrices = {
  writer: process.env.REACT_APP_WRITER_PRICE,
  agent: process.env.REACT_APP_WRITER_PRICE,
  producer: process.env.REACT_APP_PRODUCER_PRICE
}
const ForgotPasswordForm = () => {
  const c = useStyles();
  const history = useHistory();
  const { errorMessage, setError, isLoading, invoke } = useAsyncRequest();
  const [form, setForm] = useState({
    email: "",
    code: "",
    password: "",
    repeatPassword: "",
  });
  const [isConfirmation, setIsConfirmation] = useState(false);
  const confirm = (ev) => {
    ev.preventDefault();
    if (form.password !== form.repeatPassword) {
      return setError(new Error("Passwords don't match"));
    }
    invoke(async () => {
      await Auth.forgotPasswordSubmit(form.email, form.code, form.password);
      history.push("/login");
    });
  };
  const handleChange = (ev) => {
    let value = ev.currentTarget.value;
    const name = ev.currentTarget.name;
    if (name === "email") {
      value = value.toLowerCase();
    }
    setForm((currentForm) => ({ ...currentForm, [name]: value }));
  };
  const sendCode = (ev) => {
    ev.preventDefault();
    invoke(async () => {
      await Auth.forgotPassword(form.email);
      setIsConfirmation(true);
    });
  };
  return (
    <Container maxWidth="xs" className={c.container}>
      <Typography variant="h3" className={c.title}>
        New Password
      </Typography>
      {isConfirmation ? (
        <form onSubmit={confirm}>
          <TextField
            required
            fullWidth
            label="Code from email"
            type="text"
            name="code"
            value={form.code}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            fullWidth
            label="New Password"
            type="password"
            name="password"
            value={form.password}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            fullWidth
            label="Confirm Password"
            type="password"
            name="repeatPassword"
            value={form.repeatPassword}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
          {errorMessage && (
            <Typography variant="body1" className={c.error}>
              {errorMessage}
            </Typography>
          )}
          <Button
            disabled={isLoading}
            fullWidth
            className={c.button}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Update password
            {isLoading && (
              <span className={c.loaderIcon}>
                <SyncIcon className={c.uploadingIcon} />
              </span>
            )}
          </Button>
        </form>
      ) : (
        <form onSubmit={sendCode}>
          <TextField
            required
            fullWidth
            label="Email"
            name="email"
            value={form.email}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          />
          {errorMessage && (
            <Typography variant="body1" className={c.error}>
              {errorMessage}
            </Typography>
          )}
          <Button
            disabled={isLoading}
            fullWidth
            className={c.button}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Send a code
            {isLoading && (
              <span className={c.loaderIcon}>
                <SyncIcon className={c.uploadingIcon} />
              </span>
            )}
          </Button>
        </form>
      )}
      <Link className={c.link} component={NavLink} variant="body2" to="/login">
        Login
      </Link>
    </Container>
  );
};

export default function AuthPage ({ newUser }) {
  const c = useStyles()
  const history = useHistory()
  const [step, setStep] = useState(0)
  const currentAuthUserRef = useRef(null)
  const [loginError, setLoginError] = useState(null)
  const [verified, setVerified] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [signupPriceDetails, setSignupPriceDetails] = useState(null)
  const [form, setForm] = useState({
    email: '',
    password: '',
    password2: '',
    role: '',
    given_name: '',
    authenticationCode: ''
  })

  const { setUser } = React.useContext(User)
  
  useEffect(() => {
    async function getPriceDetails(){
      try {
        const priceData = await API.post('payments', '/get-subscription-details', {
          body: { price: signupPrices[form.role] }
        })
        setSignupPriceDetails(priceData)
      }

      catch(error) {
        console.log(error)
      }
    }

    if (step === 62) getPriceDetails()
  }, [step])

  useEffect(() => {
    async function createCheckout() {
      try {
        const link = await API.post("payments", "/create-checkout-session", {
          body: {
            productId: form.role,
            email: form.email,
            name: currentAuthUserRef.current?.name || form.name,
            id: currentAuthUserRef.current?.id,
            redirectTo: window.location.origin
          },
        });
        if(!link) throw new Error('Link not found...')
        let a = document.createElement('a');
        a.href = link.url;
        a.click();
      } catch(error) {
        console.log(error)
      }
    }
    if (step === 3) createCheckout()
  }, [step])

  useEffect(() => {
    setLoginError(null)
    newUser && setStep(0)
  }, [newUser])


  const handleChange = event => {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }

  const handleChangeEmail = event => {
    const value = event.target.value.toLowerCase()
    setForm({ ...form, 'email': value })
  }


  const handleSignUp = async event => {
    event.preventDefault()
    setLoginError(null)
    setSubmitting(true)

    const {
      email,
      password,
      password2,
      given_name
    } = form

    if (password !== password2) {
      setLoginError('Passwords don\'t match')
      setSubmitting(false)
      return
    }

    try {
      const signUpResponse = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name
        }
      })
      currentAuthUserRef.current = {
        id: signUpResponse.userSub,
        name: given_name
      }
      setLoginError(null)
      setStep(1)
    }

    catch (error) {
      setLoginError(error.message)
    }

    finally {
      setSubmitting(false)
    }
  }


  const handleLogin = async event => {
    event.preventDefault()
    setLoginError(null)
    setSubmitting(true)

    const { email, password } = form

    try {
      const cognitoUser = await Auth.signIn(email, password);
      currentAuthUserRef.current = {
        id: cognitoUser.attributes.sub,
        name: cognitoUser.attributes.given_name
      };
      const dbUser = await API.graphql(
        operation(getUser, { id: cognitoUser.attributes.sub })
      );
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const isFreeUser = !isSubscribed(dbUser.data.getUser);
      if (!dbUser.data.getUser || isFreeUser) {
        if (isFreeUser && dbUser.data.getUser?.role) {
          setForm((oldForm) => ({
            ...oldForm,
            role: dbUser.data.getUser.role,
          }));
        }
        setStep(2);
      } else {
        setUser(mergeProfiles(cognitoUser, dbUser));
      }
    } catch (error) {
      setLoginError(error.message);
      if (error.code === "UserNotConfirmedException") {
        setVerified(false);
        setLoginError(error.message);
      }
    } finally {
      setSubmitting(false);
    }
  }


  const handleRoleSubmit = async event => {
    event.preventDefault()
    setStep(3)
  }


  const handlePaymentComplete = async () => {
    const { password, email } = form

    try {
      setSubmitting(true)
      const _user = await Auth.signIn(email, password)
      // Doesnt work anymore, check new stripe integration
      await setUserInDB(_user.attributes.sub)
      const user = {
        ..._user.attributes,
        role: form.role
      }
      setUser(user)
    }

    catch (error) {
      setLoginError(error.message)
    }

    finally {
      setSubmitting(false)
    }
  }


  const getNewCode = async event => {
    event.preventDefault()
    setStep(1)
    setLoginError(null)

    try {
      await Auth.resendSignUp(form.email)
    }

    catch (error) {
      setLoginError(error.message)
    }
  }


  const handleCodeVerification = async event => {
    event.preventDefault()
    setLoginError(null)
    setSubmitting(true)

    const { email, authenticationCode: code } = form

    try {
      await Auth.confirmSignUp(email, code.trim())
      setVerified(true);
      history.push('/login')
      setStep(0);
    }

    catch (error) {
      setSubmitting(false)
      setLoginError(error.message)
    }
    
    finally {
      setSubmitting(false)
    }
  }


  function setUserInDB (id) {
    return new Promise((resolve, reject) => {
      const input = { id, name: form.given_name, role: form.role }

      API.graphql(operation(createUser, { input }))
        .then(resolve)
        .catch(reject)
    })
  }


  const loginForm = (
    <Container maxWidth='xs' className={c.container}>
      <Typography variant='h3' className={c.title}>
        Login
      </Typography>
      <form onSubmit={handleLogin}>
        <TextField
          required
          fullWidth
          label='Email'
          name='email'
          value={form.email}
          onChange={handleChangeEmail}
          margin='normal'
          variant='outlined'
        />
        <TextField
          required
          fullWidth
          label='Password'
          type='password'
          name='password'
          value={form.password1}
          onChange={handleChange}
          margin='normal'
          variant='outlined'
        />
        {(loginError) && (
          <Typography variant='body1' className={c.error}>
            {loginError}
          </Typography>
        )}

        {!verified && <Button
          fullWidth
          className={c.button}
          variant='contained'
          color='secondary'
          size='large'
          onClick={(event) => getNewCode(event)}
        >
          Get new confirmation code
        </Button>}

        <Button
          disabled={submitting || !verified}
          fullWidth
          className={c.button}
          type='submit'
          variant='contained'
          color='primary'
          size='large'
        >
          Log In
          {submitting && <span className={c.loaderIcon}>
            <SyncIcon className={c.uploadingIcon}/>
          </span>}
        </Button>
        <Link
          className={c.link}
          component={NavLink}
          variant='body2'
          to='/signup'
        >
          Not Yet Registered? Go Here
        </Link>
        <Link
          className={c.link}
          component={NavLink}
          variant='body2'
          to='/forgot-password'
        >
          Forgot password?
        </Link>
      </form>
    </Container>
  )


  const signUpForm = (
    <Container maxWidth='xs' className={c.container}>
      <Typography variant='h3' className={c.title}>
        Sign Up
      </Typography>
      <form onSubmit={handleSignUp}>

        <TextField
          required
          fullWidth
          label='Email'
          name='email'
          value={form.email}
          onChange={handleChangeEmail}
          margin='normal'
          variant='outlined'
        />
        <TextField
          required
          fullWidth
          label='Name'
          name='given_name'
          value={form.given_name}
          onChange={handleChange}
          margin='normal'
          variant='outlined'
        />
        <TextField
          required
          fullWidth
          label='Password'
          type='password'
          name='password'
          value={form.password}
          onChange={handleChange}
          margin='normal'
          variant='outlined'
        />
        <TextField
          required
          fullWidth
          label='Repeat Password'
          type='password'
          name='password2'
          value={form.password2}
          onChange={handleChange}
          margin='normal'
          variant='outlined'
        />
        {loginError && (
          <Typography variant='body1' className={c.error}>
            {loginError}
          </Typography>
        )}
        <Button
          disabled={submitting}
          fullWidth
          className={c.button}
          type='submit'
          variant='contained'
          color='primary'
          size='large'
        >
          Sign Up
          {submitting && <span className={c.loaderIcon}>
            <SyncIcon className={c.uploadingIcon}/>
          </span>}
        </Button>
      </form>
      <Link
        className={c.link}
        component={NavLink}
        variant='body2'
        to='/login'
      >
        Already Registered? Login Here
      </Link>
    </Container>
  )

  const userRoleForm = (
    <Container maxWidth='xs' className={c.container}>
      <Typography variant='body1' className={c.roleText}>
        We need to know how you wish to use the platform.<br/>
        If you wish to upload shows to the platform, then please choose Writer.<br/>
        If you wish to view scripts and purchase licenses, then please choose Producer.<br/>
      </Typography>

      <form onSubmit={handleRoleSubmit} className={c.roleForm}>
        <FormControl component='fieldset' className={c.role}>
          <FormLabel component='legend'>Are you a...</FormLabel>
          <RadioGroup row aria-label='flow' name='role' value={form.role} onChange={handleChange}>
            <FormControlLabel value='writer' control={<Radio/>} label='Writer'/>
            <FormControlLabel value='agent' control={<Radio/>} label='Agent'/>
            <FormControlLabel value='producer' control={<Radio/>} label='Producer'/>
          </RadioGroup>
        </FormControl>
        {form.role &&
        <Button
          disabled={submitting}
          type='submit'
          variant='contained'
          color='primary'
          size='large'
        >
          Continue as {form.role}
          {submitting && <span className={c.loaderIcon}>
            <SyncIcon className={c.uploadingIcon}/>
          </span>}
        </Button>}
      </form>
    </Container>
  )

  const codeConfirmForm = (
    <Container maxWidth='xs' className={c.container}>
      <Typography variant='h3' className={c.title}>
        Confirm Signup
      </Typography>
      <form onSubmit={handleCodeVerification}>
        <TextField
          required
          fullWidth
          label={'Authentication Code'}
          name={'authenticationCode'}
          value={form.authenticationCode}
          onChange={handleChange}
          margin='normal'
          variant='outlined'
          helperText={'Check your email for code'}
        />

        {loginError && (
          <Typography variant='body1' className={c.error}>
            {loginError}
          </Typography>
        )}

        <Button
          disabled={submitting}
          fullWidth
          className={c.button}
          type='submit'
          variant='contained'
          color='primary'
          size='large'
        >
          Confirm Signup
          {submitting && <span className={c.loaderIcon}>
            <SyncIcon className={c.uploadingIcon}/>
          </span>}
        </Button>
      </form>
    </Container>
  )

  const paymentForm = (
    !signupPriceDetails ?
    <Loader/> :
    <Container maxWidth='xs' className={c.container}>
      <Typography variant='h3' className={c.title}>
        Payment Plan
      </Typography>
      <StripePayment
        amount={signupPriceDetails.price}
        currency={signupPriceDetails.currency}
        price={signupPrices[form.role]}
        text={`Sign up as a ${form.role}`}
        afterPayment={handlePaymentComplete}
      />
    </Container>
  )
  const firstStepComponents = {
    '/signup': signUpForm,
    '/forgot-password': <ForgotPasswordForm />
  }
  const path = history.location.pathname

  const steps = [
    firstStepComponents[path] || loginForm,
    codeConfirmForm,
    userRoleForm,
    paymentForm
  ]

  return steps[step]
}