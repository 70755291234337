/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      role
      stripeID
      isRecurring
      expiredAt
      shows {
        items {
          id
          userID
          title
          author
          age
          genre
          type
          synopsis
          musicLink
          maleParts
          femaleParts
          nonBinaryParts
          ensembleParts
          isBacktrackingAllowed
          instruments
          licenseLocation
          licenseType
          licenseID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      licenses {
        items {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        role
        stripeID
        isRecurring
        expiredAt
        shows {
          nextToken
          __typename
        }
        licenses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShow = /* GraphQL */ `
  query GetShow($id: ID!) {
    getShow(id: $id) {
      id
      userID
      title
      author
      age
      genre
      type
      synopsis
      script {
        bucket
        key
        region
        __typename
      }
      score {
        bucket
        key
        region
        __typename
      }
      pitch {
        bucket
        key
        region
        __typename
      }
      poster {
        bucket
        key
        region
        __typename
      }
      musicLink
      maleParts
      femaleParts
      nonBinaryParts
      ensembleParts
      isBacktrackingAllowed
      instruments
      licenseLocation
      licenseType
      licenseID
      license {
        id
        userID
        firstDate
        lastDate
        type
        mediaLicensing
        addOnServices
        numberOfScripts
        showID
        show {
          id
          userID
          title
          author
          age
          genre
          type
          synopsis
          musicLink
          maleParts
          femaleParts
          nonBinaryParts
          ensembleParts
          isBacktrackingAllowed
          instruments
          licenseLocation
          licenseType
          licenseID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tokens {
        items {
          id
          showID
          expiredAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShows = /* GraphQL */ `
  query ListShows(
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        title
        author
        age
        genre
        type
        synopsis
        script {
          bucket
          key
          region
          __typename
        }
        score {
          bucket
          key
          region
          __typename
        }
        pitch {
          bucket
          key
          region
          __typename
        }
        poster {
          bucket
          key
          region
          __typename
        }
        musicLink
        maleParts
        femaleParts
        nonBinaryParts
        ensembleParts
        isBacktrackingAllowed
        instruments
        licenseLocation
        licenseType
        licenseID
        license {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        tokens {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLicense = /* GraphQL */ `
  query GetLicense($id: ID!) {
    getLicense(id: $id) {
      id
      userID
      firstDate
      lastDate
      type
      mediaLicensing
      addOnServices
      numberOfScripts
      showID
      show {
        id
        userID
        title
        author
        age
        genre
        type
        synopsis
        script {
          bucket
          key
          region
          __typename
        }
        score {
          bucket
          key
          region
          __typename
        }
        pitch {
          bucket
          key
          region
          __typename
        }
        poster {
          bucket
          key
          region
          __typename
        }
        musicLink
        maleParts
        femaleParts
        nonBinaryParts
        ensembleParts
        isBacktrackingAllowed
        instruments
        licenseLocation
        licenseType
        licenseID
        license {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        tokens {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLicenses = /* GraphQL */ `
  query ListLicenses(
    $filter: ModelLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        firstDate
        lastDate
        type
        mediaLicensing
        addOnServices
        numberOfScripts
        showID
        show {
          id
          userID
          title
          author
          age
          genre
          type
          synopsis
          musicLink
          maleParts
          femaleParts
          nonBinaryParts
          ensembleParts
          isBacktrackingAllowed
          instruments
          licenseLocation
          licenseType
          licenseID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSecretToken = /* GraphQL */ `
  query GetSecretToken($id: ID!) {
    getSecretToken(id: $id) {
      id
      showID
      show {
        id
        userID
        title
        author
        age
        genre
        type
        synopsis
        script {
          bucket
          key
          region
          __typename
        }
        score {
          bucket
          key
          region
          __typename
        }
        pitch {
          bucket
          key
          region
          __typename
        }
        poster {
          bucket
          key
          region
          __typename
        }
        musicLink
        maleParts
        femaleParts
        nonBinaryParts
        ensembleParts
        isBacktrackingAllowed
        instruments
        licenseLocation
        licenseType
        licenseID
        license {
          id
          userID
          firstDate
          lastDate
          type
          mediaLicensing
          addOnServices
          numberOfScripts
          showID
          createdAt
          updatedAt
          __typename
        }
        tokens {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      expiredAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSecretTokens = /* GraphQL */ `
  query ListSecretTokens(
    $filter: ModelSecretTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecretTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showID
        show {
          id
          userID
          title
          author
          age
          genre
          type
          synopsis
          musicLink
          maleParts
          femaleParts
          nonBinaryParts
          ensembleParts
          isBacktrackingAllowed
          instruments
          licenseLocation
          licenseType
          licenseID
          createdAt
          updatedAt
          __typename
        }
        expiredAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
