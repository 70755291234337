import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Loader from '../components/Loader'
import { getQueryParam } from '../helpers/window'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { createUser, updateUser } from '../graphql/mutations'
import { getObjectFields } from '../helpers/object'
import { getUser } from '../graphql/queries'
import { User } from '../helpers/contexts'

export default function OrderSuccessProcessingPage(){
  const { setUser } = useContext(User)
  const history = useHistory()
  useEffect(() => {
    const makeRequest = async () => {
      const sessionId = getQueryParam('session_id')
      try {
        if (!sessionId) {
          throw new Error("Session is invalid");
        }
        const userData = await API.post("payments", "/order-success", {
          body: { sessionId },
        });
        const dbExistingUser = await API.graphql(
          graphqlOperation(getUser, { id: userData.id })
        );
        if (dbExistingUser.data.getUser) {
          // If user already had subscription but wants to renew it
          await API.graphql(
            graphqlOperation(updateUser, {
              input: getObjectFields(userData, [
                "id",
                "expiredAt",
                "stripeID",
                "role",
              ]),
            })
          );
        } else {
          await API.graphql(graphqlOperation(createUser, { input: userData }));
        }
        setUser(userData);
        const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
        if (cognitoUser) {
          history.push("/home");
        } else {
          history.push("/login");
        }
      } catch (e) {
        console.warn(e);
        history.push('/')
      }
    }
    makeRequest();
  }, [])
  return (
    <Container maxWidth='xs' >
      <Loader />
    </Container>
  )
}