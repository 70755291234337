import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Transition } from 'react-transition-group'
import SyncIcon from '@material-ui/icons/Public'
import SuccessIcon from '@material-ui/icons/CheckCircle'
import FailIcon from '@material-ui/icons/Cancel'
import Response from '../Response'


const duration = 300

const easingFunction = 'linear'

const defaultStyle = {
  transition: `transform ${duration}ms ${easingFunction}`,
  transform: 'rotateY(90deg)',
  alignSelf: 'center',
  position: 'absolute'
}

const transitionStyles = {
  entering: { transform: 'rotateY(90deg)' },
  entered: { transform: 'rotateY(0deg)' },
  exiting: { transform: 'rotateY(90deg)' },
  exited: { transform: 'rotateY(90deg)' }
}


const useStyles = makeStyles(theme => ({
  '@global': {'@keyframes rotating': {
    '0%': {
      webkitTransform: 'rotate(360deg)',
      transform: 'rotate(360deg)'
    },
    '100%': {
      webkitTransform: 'rotate(0deg)',
      transform: 'rotate(0deg)'
    }
  }},
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    fontSize: 100
  },
  loadingIcon: {
    composes: '$icon',
    animation: 'rotating 2s linear infinite',
    color: '#000'
  },
  successIcon: {
    composes: '$icon',
    color: theme.palette.success.main
  },
  failIcon: {
    composes: '$icon',
    color: theme.palette.error.main
  },
  alert: {
    height: 'fit-content',
    alignSelf: 'flex-end',
    margin: theme.spacing(3)
  }
}))


function Loader ({ state: processingState = 'loading', response, close }) {
  const c = useStyles()

  const loading = processingState === 'loading'
  const success = processingState === 'success'
  const fail = processingState === 'fail'

  return (
    <div className={c.wrapper}>
      <Transition in={loading} timeout={duration} mountOnEnter unmountOnExit>
        {state => (
          <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
            <SyncIcon className={c.loadingIcon}/>
          </div>
        )}
      </Transition>
      <Transition in={success} timeout={duration} mountOnEnter unmountOnExit>
        {state => (
          <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
            <SuccessIcon className={c.successIcon}/>
          </div>
        )}
      </Transition>
      <Transition in={fail} timeout={duration} mountOnEnter unmountOnExit>
        {state => (
          <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
            <FailIcon className={c.failIcon}/>
          </div>
        )}
      </Transition>
      {response && <Response response={response} closeAlert={close}/>}
    </div>
  )
}

export default Loader
