import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4)
  }
}))


export default function Contact(){
  const c = useStyles()

  return (
    <Container maxWidth='xs' className={c.container}>
      <Typography>
        For any question contact us at <a href='mailto:hello@crimsonphoenix.co.uk'>hello@crimsonphoenix.co.uk</a>
      </Typography>
    </Container>
  )
}