export function mergeProfiles(cognitoUser, dbUser) {
  return {
    id: cognitoUser.attributes.sub,
    name: cognitoUser.attributes.given_name,
    ...dbUser.data.getUser,
  };
}

export function getUserFunctionalRole (user) {
  if (!user) {
    return null
  }
  if (user.role === 'agent') {
    return 'writer'
  }
  return user.role
}