import { ListItemSecondaryAction } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'
import { listShows } from '../graphql/queries'
import { listLicenses } from '../graphql/queries-override'
import { User } from '../helpers/contexts'
import EditIcon from '@material-ui/icons/Edit'
import ViewIcon from '@material-ui/icons/Visibility'


const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(2),
    columns: 2,
    [theme.breakpoints.down('xs')]: {
      columns: 1
    },
    columnGap: theme.spacing(4)
  },
  headerText: {
    marginTop: theme.spacing(1)
  },
  icon: {
    marginRight: 40
  },
  img: {
    height: 150,
    width: 100
  }
}))


export default function MyList ({ uploads, licenses }) {
  const c = useStyles()
  const { user } = React.useContext(User)
  const [shows, setShows] = useState(null)
  useEffect(() => {
    getShows()

    async function getShows () {
      try {
        const methods = {
          listShows,
          listLicenses
        }
        const method = uploads ? 'listShows' : 'listLicenses'
        const query = methods[method]
        const { data } = await API.graphql(
          graphqlOperation(query, {
            filter: {
              userID: {
                eq: user.id,
              },
            },
          }));
        const items = data[method].items
        const _shows = uploads ?
          items :
          items.map(license => license.show)
        const _pictureURLs = _shows.filter(show => show.poster?.key).map(async show => await Storage.get(show.poster.key))
        const pictureURLs = await Promise.all(_pictureURLs)

        for (let i = 0; i < _shows.length; i++) {
          _shows[i].posterUrl = pictureURLs[i]
        }

        setShows(_shows)
      }

      catch (error) {
        console.log(error)
      }
    }
  }, [])


  return (
    <>
      {shows && <Typography variant='h4'>{shows.length ? 'My Shows' : 'You do not have any shows yet. Upload a show.'}</Typography>}
      <List component='nav' aria-label='list of shows'>
        {shows && shows.map(({ title, posterUrl, id }) =>
          <ListItem
            button
            key={id}
            component={Link}
            to={uploads ? `show/${id}/edit/` : `show/${id}/view-license/`}
          >
            <ListItemIcon className={c.icon}>
              <img src={posterUrl} alt={title} className={c.img}/>
            </ListItemIcon>
            <ListItemText primary={title}/>
            <ListItemSecondaryAction>
              <IconButton edge='end'>
                {uploads ?
                  <EditIcon component={Link} to={`show/${id}/edit/`}/> :
                  <ViewIcon component={Link} to={`show/${id}/view-license/`}/>
                }
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    </>
  )
}