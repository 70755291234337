export function getQueryParam (param, sanitize = true) {
  const url = new URL(window.location.href)
  const params = Object.fromEntries(url.searchParams.entries())
  let rawParam = params[param]

  if (rawParam && sanitize) {
    rawParam = window.decodeURI(rawParam).replace('%23', '#')
  }
  return rawParam
}

export function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges();
  } else if (document.selection) {
    document.selection.empty();
  }
}