import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'


const useStyles = makeStyles(theme => ({
  scriptButton: {
    marginTop: theme.spacing(5)
  }
}))



export default function GetLicense ({ show, script }) {
  const c = useStyles()

  const firstDate = moment(show.license.firstDate).format('MMMM Do YYYY')
  const lastDate = moment(show.license.lastDate).format('MMMM Do YYYY')
  const purchaseDate = moment(show.license.createdAt).format('MMMM Do YYYY')

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h6' margin='normal'>
          License for {show.title}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography>
          This is a license for {show.title} which will be produced between {firstDate} and {lastDate}.
          This license permits you to:
          <ul>
            <li>Perform the show at the chosen venue between {firstDate} and {lastDate}.</li>
            <li>Provide {show.license.numberOfScripts} users with scripts and/or scores</li>
          </ul>
          It also permits you to:
          <ul>
            <li>Record the show and use video and audio for marketing purposes only</li>
          </ul>
          If your licence is for an amateur performance, all publicity, including any poster and theatre programme, should clearly state that it is:
          <ul>
            <li>‘An amateur production by arrangement with <i>IPresario.</i>’</li>
          </ul>
          License purchase date: {purchaseDate}
          License expiry date: {lastDate}
        </Typography>
        <Button
          variant='contained'
          size='large'
          color='primary'
          className={c.scriptButton}
          component='a'
          href={script}
        >
          Download Script PDF
        </Button>
      </Grid>
    </>
  )
}
