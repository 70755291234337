import React, { useEffect, useState } from 'react'
import { API, Auth, graphqlOperation as operation } from 'aws-amplify'
import { getUser } from '../graphql/queries'
import { User, UploadForm, Shows } from '../helpers/contexts'
import { page1, page2 } from '../helpers/uploadForm'
import Routes from '../components/Router'
import Loader from '../components/Loader'
import { mergeProfiles } from '../helpers/users'

export default function App () {
  const [user, setUser] = useState(null)
  const [shows, setShows] = useState(null)
  const [showFilters, setShowFilters] = useState({
    type: [],
    age: [],
    genre: []
  })
  const [pageReady, setPageReady] = useState(false)
  const [uploadPage1, setUploadPage1] = useState(page1)
  const [uploadPage2, setUploadPage2] = useState(page2)

  useEffect(() => {
    async function _determineAuth () {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        const dbUser = await API.graphql(operation(getUser, {id: cognitoUser.attributes.sub}))
        if (!dbUser.data.getUser) {
          throw new Error('User not found')
        }
        setUser(mergeProfiles(cognitoUser, dbUser))
      } finally {
        setPageReady(true)
      }
    }

    _determineAuth()
  }, [])

  useEffect(() => {
    if (user) setPageReady(true)
  }, [user])


  if (!pageReady) return <Loader/>

  return (
    <User.Provider value={{ user, setUser }}>
      <Shows.Provider value={{ shows, setShows, showFilters, setShowFilters }}>
        <UploadForm.Provider
          value={{ uploadPage1, setUploadPage1, uploadPage2, setUploadPage2 }}
        >
          <Routes/>
        </UploadForm.Provider>
      </Shows.Provider>
    </User.Provider>
  )
}
