import { makeStyles } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from "react";
import { clearSelection } from '../helpers/window';

const useStyles = makeStyles((theme) => ({
  field: {
    opacity: 0,
    width: 0,
    position: 'absolute',
    zIndex: '-1',
    pointerEvents: 'none'
  },
}));
export function CopyableInput({ children, value }) {
  const c = useStyles()
  const inputRef = useRef(null);
  const timerRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);
  const copy = useCallback(() => {
    clearTimeout(timerRef.current);
    if (!inputRef.current) {
      return;
    }
    inputRef.current.select();
    document.execCommand("copy");
    setIsCopied(true);
    clearSelection();
    inputRef.current.blur();
    timerRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }, []);

  useEffect(() => () => {
    clearTimeout(timerRef.current);
  }, []);
  return (
    <>
      {children({ copy, isCopied })}
      <textarea ref={inputRef} value={value} className={c.field}></textarea>
    </>
  );
}
