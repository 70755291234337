import React, { useEffect, useState } from 'react'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { listShows } from '../graphql/queries'
import { Shows } from '../helpers/contexts'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import ListSubheader from '@material-ui/core/ListSubheader'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import Loader from '../components/Loader'
import Typography from '@material-ui/core/Typography'



const useStyles = makeStyles({
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  }
})


export default function TitlesGrid () {
  const [ shows, setShows ] = useState(null)
  const [ filteredShows, setFilteredShows ] = useState(null)
  const { showFilters } = React.useContext(Shows)
  const c = useStyles()


  useEffect(() => {
    getAllShows()

    async function getAllShows(){
      try {
        const res = await API.graphql(graphqlOperation(listShows))
        const _shows = res.data.listShows.items
        const _pictureURLs = _shows.map(async show => await Storage.get(show.poster.key))
        const pictureURLs = await Promise.all(_pictureURLs)

        for (let i = 0; i < _shows.length; i++) {
          _shows[i].posterUrl = pictureURLs[i]
        }

        setFilteredShows(_shows)
        setShows(_shows)
      }

      catch (error) {
        console.log(error)
      }
    }
  }, [])

  useEffect(() => {
    if (shows) {

      const _filteredShows = Object.keys(showFilters).reduce((filtered, filterType, index) => {
        let filterList = showFilters[filterType]

        let _categoryFiltered = filtered.filter(show => {
          return filterList.includes(show[filterType])
        })

        return (filterList.length && filtered.length) ? _categoryFiltered : filtered
      }, [...shows])      

      setFilteredShows(_filteredShows)
    }
  }, [showFilters])


  if (!shows) return <Loader/>

  return (
    <GridList>
      <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
        <ListSubheader component="div">Shows</ListSubheader>
      </GridListTile>
      {shows.length ? filteredShows.length && filteredShows.map(({ title, author, posterUrl, id }) => (
        <GridListTile
          key={id}
          cols={0.66667}
          rows={2}
          component={Link}
          to={'/show/' + id}
        >
          <img src={posterUrl} alt={title}/>
          <GridListTileBar
            title={title}
            subtitle={<span>by: {author}</span>}
            actionIcon={
              <IconButton aria-label={`info about ${title}`} className={c.icon}>
                <InfoIcon/>
              </IconButton>
            }
          />
        </GridListTile>
      )) :
        <Typography variant='body2'>No Shows</Typography>
      }
    </GridList>
  )
}