import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles({
  musicPlayer: {
    width: '100%',
    overflow: 'hidden',
    background: 'transparent',
    borderRadius: 10
  }
})


export default function MusicEmbed ({ link }) {
  const [src, setSrc] = useState(null)
  const c = useStyles()

  useEffect(() => {
    if (link && link.search('apple') !== -1) {
      setSrc(`https://embed.${link.replace('https://', '')}`)
    }

    else if (link && link.search('spotify') !== -1) {
      const _link = link.replace('https://open.spotify.com/album/', '')
      setSrc(`https://open.spotify.com/embed/album/${_link}`)
    }
  }, [link])

  return (
    <iframe
      title='musicPlayer'
      allow='autoplay *; encrypted-media *; fullscreen *'
      frameBorder='0'
      height='400'
      className={c.musicPlayer}
      sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation'
      src={src}
    />
  )
}