import React, { useEffect, useState } from 'react'
import { API, Storage } from 'aws-amplify'
import { createLicense, updateShow } from '../../graphql/mutations'
import { makeStyles } from '@material-ui/core/styles'
import { Link, Redirect, useParams } from 'react-router-dom'
import { User } from '../../helpers/contexts'
import useGetShow from '../../helpers/customHooks/useGetShow'
import priceCalculation from '../../helpers/priceCalculation'
import GetLicense from './GetLicense'
import ViewLicense from './ViewLicense'
import Info from './Info'
import ScriptPreview from './ScriptPreview'
import Loader from '../../components/Loader'
import StripePayment from '../../components/StripePayment'
import LicensePrice from '../../components/LicensePrice'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import { ChevronLeft } from '@material-ui/icons'


const useStyles = makeStyles((theme) => ({
  poster: {
    width: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  essentialInformation: {
    display: 'flex'
  },
  icons: {
    flex: 1,
    paddingRight: theme.spacing(3)
  },
  musicInfo: {
    display: 'flex',
    flex: 3,
    flexDirection: 'column'
  },
  instruments: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  info: {
    marginLeft: theme.spacing(2),
    color: 'gray'
  },
  musicPlayer: {
    width: '100%',
    overflow: 'hidden',
    background: 'transparent',
    borderRadius: 10
  },
  buttons: {
    marginTop: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  backActionBlock: {
    marginBottom: '15px'
  },
  backAction: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
  backActionLabel: {
    marginLeft: '5px'
  },
}))



function todayDateISON () {
  const today = new Date()
  return today.toISOString().split('T')[0]
}

function tomorrowDateISON () {
  const tomorrow = new Date()
  const followingDay = new Date(tomorrow.getTime() + 86400000) // + 1 day in ms
  return followingDay.toISOString().split('T')[0]
}

const initLicenseForm = {
  type: '',
  firstDate: todayDateISON(),
  lastDate: tomorrowDateISON(),
  mediaLicensing: 'No',
  addOnServices: [
    { name: 'Set Design', value: false, price: 1000 },
    { name: 'Video Recording', value: false, price: 1000 },
    { name: 'Marketing Support', value: false, price: 1000 },
    { name: 'Costume Production', value: false, price: 1000 }
  ],
  numberOfScripts: 1,
  performanceCount: 0,
  venueCapacity: 0,
  ticketPrice: 1,
}


export default function Show ({ info, getLicense, viewLicense, script, score, pitch, isPublic }) {
  const hasBackAction = !!(getLicense || viewLicense || script || score || pitch) && !isPublic
  const [licenseForm, setLicenseForm] = useState(initLicenseForm)
  const [pricingDetails, setPricingDetails] = useState(null)
  const [prices, setPrices] = useState(null)
  const [submitText, setSubmitText] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [scriptPDF, setScriptPDF] = useState(null)
  const [scorePDF, setScorePDF] = useState(null)
  const [pitchPDF, setPitchPDF] = useState(null)
  const [buttonLink, setButtonLink] = useState('')
  const c = useStyles()
  const { id } = useParams()
  const { user } = React.useContext(User)
  const authUser = user || {}
  const { show, pageLoading } = useGetShow(id, true)
  const hasAnyFile = script || score || pitch

  function isTheaterProduction() {
    return (show.type === 'Musical' || show.type === 'Play')
  }


  useEffect(() => {
    show && getFiles()

    async function getFiles (){
      try {
        const scriptUrl = await Storage.get(show.script.key, {level: isPublic ? 'public' : '', download: false})
        setScriptPDF(scriptUrl)
        if (show.score?.key) {
          const scoreUrl = await Storage.get(show.score.key)
          setScorePDF(scoreUrl)
        }
        if (show.pitch?.key) {
          setPitchPDF(await Storage.get(show.pitch.key))
        }
      }
      catch (error){
        console.log(error)
      }
    }
  }, [show])


  useEffect(() => {
    if (show && show.licenseType.length === 1) {
      setLicenseForm({ ...licenseForm, 'type': show.licenseType.toString() })
    }

    let _submitText
    let _buttonLink = ''

    if (show?.license?.userID === authUser.id) _submitText = 'View License'
    else if (getLicense) _submitText = 'Purchase License'
    else _submitText ='License Show'
    setSubmitText(_submitText)

    if (show && info) _buttonLink =  `/show/${show.id}/get-license`
    if (show?.license?.userID === authUser.id) _buttonLink =  `/show/${show?.id}/view-license`
    setButtonLink(_buttonLink)
  }, [show, getLicense, viewLicense])


  useEffect(() => {
    show && setPricingDetails({
      showType: show.type,
      licenseType: (show.licenseType.length < 2) ? show.licenseType[0] : licenseForm.type,
      addOnServices: licenseForm.addOnServices.filter(service => service.value).map(service => service.name),
      mediaLicensing: (licenseForm.mediaLicensing === 'Yes'),
      isTheaterProduction: isTheaterProduction(),
      tickets: {
        price: licenseForm.ticketPrice,
        performances: licenseForm.performanceCount,
        venueCapacity: licenseForm.venueCapacity
      },
      numberOfScripts: licenseForm.numberOfScripts
    })
  }, [show, licenseForm])


  useEffect(() => {
    pricingDetails && setPrices(priceCalculation(pricingDetails))
  }, [pricingDetails])


  function handleChange (event) {
    const { name, value } = event.target

    if (value < 0) return

    setLicenseForm({ ...licenseForm, [name]: value })
  }


  function handleCheckboxChange (event) {
    const { name, checked } = event.target

    const _addOnServices = licenseForm.addOnServices.map(service => {
      if (service.name === name) {
        return {
          ...service,
          value: checked
        }
      }
      return service
    })

    setLicenseForm({
      ...licenseForm,
      addOnServices: _addOnServices
    })
  }


  function preventSubmit () {
    if (
      licenseForm.lastDate <= licenseForm.firstDate ||
      !licenseForm.type ||
      !licenseForm.mediaLicensing
    ) return true
  }


  function submitLicense(){
    const input = {
      showID: show.id,
      userID: authUser.id,
      type: licenseForm.type,
      firstDate: licenseForm.firstDate,
      lastDate: licenseForm.lastDate,
      mediaLicensing: licenseForm.mediaLicensing,
      numberOfScripts: Number(licenseForm.numberOfScripts)
    }

    input.mediaLicensing = licenseForm.mediaLicensing === 'Yes'
    input.addOnServices = licenseForm.addOnServices
      .filter(service => service.value)
      .map(service => service.name)

    return new Promise(async (resolve, reject) => {
      try {
        const license = await API.graphql({
          query: createLicense,
          variables: { input }
        })

        const licenseID = license.data.createLicense.id

        await API.graphql({
          query: updateShow,
          variables: {
            input: {
              id: show.id,
              licenseID
            }
          }
        })

        resolve()
      }

      catch (error) {
        reject(error.errors[0].message)
      }
    })
  }


  const handleOpenModal = () => { setOpenDialog(true) }
  const handleCloseModal = () => { setOpenDialog(false) }


  if (getLicense && (show?.license?.userID === authUser.id)) {
    return <Redirect to='/home' />
  }

  if (pageLoading) return <Loader/>

  return <>
    <Dialog open={openDialog} onClose={handleCloseModal}>
      <StripePayment
        amount={prices?.totalPrice}
        pricing={pricingDetails}
        currency='gbp'
        text={`License ${show.title} for `}
        disablePayment={false}
        closeModal={handleCloseModal}
        afterPayment={submitLicense}
      />
    </Dialog>
    {hasBackAction && <div className={c.backActionBlock}>
      <Link className={c.backAction} to={`/show/${id}`}><ChevronLeft /><span className={c.backActionLabel}>Back to the show</span></Link>
    </div>}
    <Grid container spacing={4}>
      <Grid item xs={(hasAnyFile) ? 3 : 4} md={(hasAnyFile) ? 2 : 3}>
        {isPublic && <Typography variant='h6'>
                {show.title}
              </Typography>}
        <img src={show.posterURL} className={c.poster} alt={show.title}/>
        {(getLicense && prices && show) &&
          <LicensePrice prices={prices} isTheaterProduction={isTheaterProduction} />
        }
      </Grid>
      {(script && scriptPDF) &&
        <Grid item xs={9} md={10}>
          <ScriptPreview link={scriptPDF} />
        </Grid>
      }
      {(score && scorePDF) &&
        <Grid item xs={9} md={10}>
          <ScriptPreview link={scorePDF} />
        </Grid>
      }
      {(pitch && pitchPDF) &&
        <Grid item xs={9} md={10}>
          <ScriptPreview link={pitchPDF} />
        </Grid>
      }
      {(!hasAnyFile) &&
        <Grid item xs={8} md={9}>
          <Grid container spacing={getLicense ? 2 : 4}>
            <Grid item xs={12} className={c.header}>
              <Typography variant='h4'>
                {show.title}
              </Typography>
              <div className={c.buttons}>
                <Button
                  variant='contained'
                  color='primary'
                  className={c.scriptButton}
                  component={Link}
                  to={`/show/${show.id}/script`}
                >
                  Read Script
                </Button>
                {!!(isTheaterProduction() && scorePDF) && <Button
                  variant='contained'
                  color='primary'
                  className={c.button}
                  component={Link}
                  to={`/show/${show.id}/score`}
                >
                  Read Score
                </Button>}  
                {!!(pitchPDF && show.type === 'TV Series') && <Button
                  variant='contained'
                  color='primary'
                  className={c.button}
                  component={Link}
                  to={`/show/${show.id}/pitch`}
                >
                  Read Pitch
                </Button>}  
                <Button
                  variant='contained'
                  color='primary'
                  className={c.button}
                  component={getLicense ? 'button' : Link}
                  onClick={getLicense && handleOpenModal}
                  to={buttonLink}
                  disabled={(getLicense && preventSubmit()) || viewLicense}
                >
                  {submitText}
                </Button>
              </div>
            </Grid>

            {info && <Info show={show}/>}

            {getLicense && <GetLicense
              show={show}
              licenseForm={licenseForm}
              handleChange={handleChange}
              handleCheckboxChange={handleCheckboxChange}
              isTheaterProduction={isTheaterProduction}
            />}

            {viewLicense && <ViewLicense show={show} script={scriptPDF} />}
          </Grid>
        </Grid>
      }
    </Grid>
  </>
}
