import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import Button from '@material-ui/core/Button'


const useStyles = makeStyles((theme) => ({
  alert: {
    height: 'fit-content',
    alignSelf: 'flex-end',
    margin: theme.spacing(3)
  }
}))

export default function Response ({ response, closeAlert, closeText = 'OK' }) {
  const c = useStyles()

  return <Alert
    variant='filled'
    severity={response.type}
    className={c.alert}
    action={
      <Button
        color='inherit'
        size='small'
        onClick={closeAlert}
      >
        {closeText}
      </Button>
    }
  >
    {response.text}
  </Alert>
}
