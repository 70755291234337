export function PlayIcon({ ...attrs }) {
  return (
    <svg
      width="1em"
      height="1em"
      {...attrs}
      viewBox="-3 0 28 28"
      version="1.1"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-419.000000, -571.000000)"
          fill="currentColor"
        >
          <path
            d="M440.415,583.554 L421.418,571.311 C420.291,570.704 419,570.767 419,572.946 L419,597.054 C419,599.046 420.385,599.36 421.418,598.689 L440.415,586.446 C441.197,585.647 441.197,584.353 440.415,583.554"
          ></path>
        </g>
      </g>
    </svg>
  );
}
