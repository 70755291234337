import { ClickAwayListener } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import React, { useState } from 'react'


const useStyles = makeStyles((theme) => ({
  icon: {
    alignSelf: 'center'
  },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 260,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 180
    },
    whiteSpace: 'pre-line',
  }
}))


export default function InfoTooltip ({ placement = 'right', title }) {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const c = useStyles()

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        classes={{tooltip: c.tooltip}}
        disableTouchListener
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        open={open}
        placement={placement}
        title={title}
      >
        <InfoIcon className={c.icon} onClick={handleTooltipOpen}/>
      </Tooltip>
    </ClickAwayListener>
  )
}