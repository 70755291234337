import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import ShareIcon from "@material-ui/icons/Share";
import { API, Storage } from "aws-amplify";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import Response from "../components/Response";
import { updateShow } from "../graphql/mutations";
import { UploadForm, User } from "../helpers/contexts";
import useGetShow from "../helpers/customHooks/useGetShow";
import {
  configureS3Object,
  preventSubmit,
  page1,
  allowedFilesByType,
} from "../helpers/uploadForm";
import ShareScriptDialog from "../components/ShareScriptDialog";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(4),
  },
  mainColumn: {
    flex: "0 0 50%",
    [theme.breakpoints.down("xs")]: {
      flex: "1 1 100%",
    },
  },
  headerText: {
    marginTop: theme.spacing(1),
  },
  dropdown: {
    width: "100%",
  },
  formItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "&:first-child": {
      marginTop: 0,
    },
  },
  fileInput: {
    display: "none",
  },
  fileInputText: {
    width: "60%",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  posterSizeText: {
    fontSize: "0.75rem",
    marginTop: theme.spacing(1),
  },
  splitForm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  uploadIcon: {
    marginLeft: theme.spacing(1),
  },
  shareButtonWrapper: {
    display: "flex",
  },
  scriptButtons: {
    display: "flex",
    flexWrap: 'wrap'
  },
  scriptButton: {
    marginRight: '1em',
    marginBottom: '3px'
  },
  infoIcon: {
    cursor: 'help'
  },
  numberInputContainer: {
    display: "flex",
    alignItems: "center",
  },
  numberInput: {
    width: 90,
    flex: "0 0 auto",
    marginLeft: "auto",
  },
  nextButton: {
    width: 200,
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row-reverse",
    height: 95,
    alignItems: "flex-end",
  },
}));

const uploadText1 =
  'Below is what you currently uploaded. Make your changes and click "Save"';
const uploadText2 = (
  <>
    You can upload files ready for licencing here.
    <br />
    These files are assessed for quality before we make the available on the
    platform.
    <br />
    Please only upload finished works.
  </>
);

export default function ShowForm1({ update }) {
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const { id } = useParams();
  const { user } = React.useContext(User);
  const { uploadPage1: showForm, setUploadPage1: setShowForm } =
    React.useContext(UploadForm);

  const { show, pageLoading } = useGetShow(id);
  const [response, setResponse] = useState(null);
  const [pristine, setPristine] = useState(true);
  const [filesToDisplay, setFilesToDisplay] = useState(["script"]);
  const c = useStyles();
  useEffect(() => {
    let _showForm = showForm.map((item) => {
      if (item.name === "author" && user.role === "writer") {
        return {
          ...item,
          value: user.name,
        };
      }
      return item;
    });
    if (update && show) {
      _showForm = showForm.map((item) => {
        if (show[item.name]) {
          let value = show[item.name];
          if (item.type === "file") {
            value = value.key
              .substring(show[item.name].key.indexOf("---"))
              .substring(3);
          }
          if (item.name === "type") {
            setFilesToDisplay(allowedFilesByType[value] || ["script"]);
          }
          return { ...item, value };
        }
        return item;
      });
    }
    setShowForm(_showForm);

    return () => {
      update && setShowForm(page1);
    };
  }, [show, update, user]);

  function handleChange(event) {
    setPristine(false);
    const { name, value, type, checked } = event.target;

    if (type === "number" && value < 0) return;
    if (name === "author" && user.role === "writer") return;
    if (name === "type") {
      setFilesToDisplay(allowedFilesByType[value] || ["script"]);
    }
    const _showForm = showForm.map((input) => {
      if (name === input.name) {
        return {
          ...input,
          value: type === "checkbox" ? checked : value,
          changed: true,
        };
      }

      return input;
    });

    setShowForm(_showForm);
  }

  function handleFileChange(event) {
    setPristine(false);

    const name = event.target.name;
    const _file = event.target.files[0];

    if (!_file) return;

    const _showForm = showForm.map((input) => {
      if (name === input.name) {
        return {
          ...input,
          value: _file.name,
          file: _file,
          changed: true,
        };
      }
      return input;
    });

    setShowForm(_showForm);
  }

  async function handleUpdateShow() {
    const input = { id };

    showForm.forEach((item) => {
      if (item.changed) {
        if (item.type === "file") {
          const { bucket, key, region } = configureS3Object(item);
          Storage.put(key, item.file, { level: "public" }).catch(console.log);
          input[item.name] = { bucket, key, region };
        } else {
          input[item.name] = item.value;
        }
      }
    });

    try {
      await API.graphql({
        query: updateShow,
        variables: { input },
      });

      setResponse({
        type: "success",
        text: "Changes saved. Click My Show to edit another show.",
      });
    } catch (error) {
      setResponse({
        type: "error",
        text: error.errors[0].message,
      });
    } finally {
      const _showForm = showForm.map((input) => {
        return { ...input, changed: false };
      });

      setShowForm(_showForm);
    }
  }

  const itemsPerColumnMapper = [7, Infinity];
  const columns = itemsPerColumnMapper.length;
  if (update && pageLoading) return <Loader />;
  const getShowFormItems = (colIndex) => {
    const itemsPerColumn = itemsPerColumnMapper[colIndex] || Infinity;
    const prevItemsPerColumn = itemsPerColumnMapper[colIndex - 1] || 0;
    return showForm.filter((_, formIndex) => {
      return (
        (colIndex + 1) * itemsPerColumn > formIndex &&
        formIndex >= colIndex * prevItemsPerColumn
      );
    });
  };
  const shouldDisplayInputTitle = (input) => {
    return (
      (filesToDisplay.includes(input.name) && filesToDisplay.length > 1) ||
      !input.value
    );
  };
  return (
    <>
      <Typography variant="h4">
        {update ? `Edit your upload: ${show.title}` : "Upload a Show"}
      </Typography>
      <Typography variant="body1" className={c.headerText}>
        {update ? uploadText1 : uploadText2}
      </Typography>
      <form className={c.main}>
        {Array.from({ length: columns }).map((_, columnIndex) => {
          return (
            <div key={columnIndex} className={c.mainColumn}>
              {getShowFormItems(columnIndex).map((input, formIndex) => {
                if (input.type === "text") {
                  return (
                    <TextField
                      key={formIndex}
                      required={input.required}
                      fullWidth
                      label={input.title}
                      type="text"
                      name={input.name}
                      value={input.value}
                      disabled={
                        input.name === "author" && user.role === "writer"
                      }
                      onChange={handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  );
                }

                if (input.type === "textfield") {
                  let characterLength = 500;

                  return (
                    <TextField
                      key={formIndex}
                      required={input.required}
                      multiline
                      rows={4}
                      fullWidth
                      label={input.title}
                      type="text"
                      name={input.name}
                      value={input.value}
                      placeholder={input.placeholder}
                      onChange={handleChange}
                      margin="normal"
                      variant="outlined"
                      error={input.value.length > characterLength}
                      helperText={
                        input.value.length > characterLength &&
                        `${characterLength} characters exceeded`
                      }
                    />
                  );
                }

                if (input.type === "radio") {
                  return (
                    <FormControl
                      key={formIndex}
                      component="fieldset"
                      className={c.formItem}
                      required={input.required}
                    >
                      <FormLabel component="legend">{input.title}</FormLabel>
                      <RadioGroup
                        row
                        name={input.name}
                        value={input.value}
                        onChange={handleChange}
                      >
                        {input.options.map((option, index) => (
                          <FormControlLabel
                            key={"inner" + index}
                            value={option}
                            control={<Radio />}
                            label={option}
                            labelPlacement="end"
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  );
                }
                if (input.type === "checkbox") {
                  return (
                    <FormControlLabel
                      key={formIndex}
                      className={c.formItem}
                      control={
                        <Checkbox
                          name={input.name}
                          onChange={handleChange}
                          checked={input.value}
                        />
                      }
                      label={input.title}
                    />
                  );
                }
                if (input.type === "dropdown") {
                  return (
                    <FormControl
                      key={formIndex}
                      required={input.required}
                      variant="outlined"
                      className={clsx(c.dropdown, c.formItem)}
                    >
                      <InputLabel id={input.name}>{input.title}</InputLabel>
                      <Select
                        labelId={input.name}
                        name={input.name}
                        value={input.value}
                        onChange={handleChange}
                        label={input.title}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {input.options.map((option, index) => (
                          <MenuItem key={"inner" + index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }

                if (input.type === "multi-dropdown") {
                  return (
                    <FormControl
                      key={formIndex}
                      required={input.required}
                      variant="outlined"
                      className={clsx(c.dropdown, c.formItem)}
                    >
                      <InputLabel id={input.name}>{input.title}</InputLabel>
                      <Select
                        multiple
                        labelId={input.name}
                        name={input.name}
                        value={input.value}
                        onChange={handleChange}
                        label={input.title}
                      >
                        {input.options.map((option, index) => (
                          <MenuItem key={"inner" + index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }

                if (
                  input.type === "file" &&
                  (filesToDisplay.includes(input.name) ||
                    input.name === "poster")
                ) {
                  return (
                    <div
                      key={formIndex}
                      className={clsx(c.formItem, c.splitForm)}
                    >
                      <Typography
                        color="textSecondary"
                        className={c.fileInputText}
                      >
                        {shouldDisplayInputTitle(input)
                          ? input.title
                          : input.value || input.title}
                        {input.required && "*"}
                      </Typography>
                      {shouldDisplayInputTitle(input) && (
                        <Typography
                          color="textSecondary"
                          className={c.fileInputText}
                        >
                          {input.value}
                        </Typography>
                      )}
                      <input
                        id={`upload-file-${input.name}`}
                        name={input.name}
                        className={c.fileInput}
                        type="file"
                        accept={input.fileType}
                        onChange={handleFileChange}
                      />
                      <div class={c.scriptButtons}>
                        <Button
                          component="label"
                          htmlFor={`upload-file-${input.name}`}
                          variant="outlined"
                          color="primary"
                          className={c.scriptButton}
                        >
                          Select File <AddIcon className={c.uploadIcon} />
                        </Button>
                        {input.name === "script" && !!show && (
                          <>
                            <div className={c.shareButtonWrapper}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setIsShareDialogOpen(true)}
                              >
                                Public Link{" "}
                                <ShareIcon className={c.uploadIcon} />
                              </Button>
                              <Tooltip title={<>
                                Share a copyable link to your script outside of iPresario.<br />You can also disable the link
                                </>} aria-label="Share a copyable link to your script outside of iPresario. You can also disable the link" placement="top">
                                <InfoIcon className={c.infoIcon} />
                              </Tooltip>
                            </div>
                            <ShareScriptDialog
                              open={isShareDialogOpen}
                              onClose={() => setIsShareDialogOpen(false)}
                              tokens={show.tokens?.items || []}
                              showId={show.id}
                            />
                          </>
                        )}
                      </div>
                      {input.name === "poster" && (
                        <Typography
                          color="textSecondary"
                          className={c.posterSizeText}
                        >
                          Please upload an image with the following sizes: width
                          900px, height 1600px
                        </Typography>
                      )}
                    </div>
                  );
                }

                if (input.type === "number") {
                  return (
                    <div key={formIndex} className={c.numberInputContainer}>
                      <Typography color="textSecondary">
                        {input.title}
                      </Typography>
                      <TextField
                        key={formIndex}
                        className={c.numberInput}
                        required={input.required}
                        type="number"
                        name={input.name}
                        value={input.value}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                  );
                }
                return null;
              })}
              {columnIndex === columns - 1 && (
                <div className={c.buttonDiv}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={c.nextButton}
                    onClick={update && handleUpdateShow}
                    component={update ? "button" : Link}
                    to="/license"
                    disabled={preventSubmit(showForm) || pristine}
                  >
                    {update ? "Save" : "Next"}
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </form>
      {response && (
        <Response
          response={response}
          closeAlert={() => {
            setResponse(null);
          }}
        />
      )}
    </>
  );
}
