export const types = [
  'TV Series',
  'Film',
  'Play',
  'Musical'
]

export const genres = [
  'Thriller',
  'Action',
  'Romance',
  'Historical',
  'Mystery',
  'Murder Mystery',
  'Fantasy',
  'Crime',
  'Comedy',
  'Adventure',
  'Action',
  'Horror',
  'Science Fiction'
]

export const age = [
  'under 12',
  '12 - 15',
  '16+',
  '18+ (adults)'
]

export const instruments = [
  'Violin',
  'Viola',
  'Cello',
  'Double Bass',
  'Flute',
  'Piccolo',
  'Oboe',
  'Bassoon',
  'Clarinet',
  'Saxophone',
  'Trumpet',
  'Trombone',
  'Tuba',
  'Drum Kit',
  'Piano',
  'Bass Guitar',
  'Electric Guitar',
  'Acoustic Guitar'
]