import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import background from "../images/login-bacground.png";
import logo from "../images/logo.svg";
import VideoPlayer from '../components/VideoPlayer'

const childrenAreaSize = 65;
const wrapMarginTop = 4;

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    inset: 0,
    background: {
      image: `url(${background})`,
      repeat: "no-repeat",
      size: "cover",
      position: "center",
    },
    transform: (formOpen) =>
      formOpen ? `translateX(-${childrenAreaSize}vw)` : "",
    transition: "transform 600ms cubic-bezier(0, 0, 0, 1.07)",
  },
  buttons: {
    position: "absolute",
    top: "2vh",
    right: "4vw",
  },
  leftColumnWrapper: {
    position: "absolute",
    top: `${wrapMarginTop + 4}vh`,
    left: 0,
    width: (formOpen) => formOpen ? '35vw' : 975,
    maxWidth: (formOpen) => formOpen ? '35vw' : '100%'
  },
  leftColumn: {
    paddingLeft: "7vw",
    paddingRight: (formOpen) => formOpen ? '3vw' : '7vw',
    transform: (formOpen) => formOpen ? 'translateX(-4vw)' : '',
    transition: "transform 600ms cubic-bezier(0, 0, 0, 1.07)",
  },
  logoWrapper: {
    width: "29vw",
    maxWidth: 300,
  },
  logo: {
    maxWidth: 285,
  },
  subtitle: {
    marginTop: theme.spacing(3),
    color: "white",
  },
  authContainer: {
    width: `${childrenAreaSize}vw`,
    position: "absolute",
    right: 0,
    marginTop: `${wrapMarginTop + 0.5}vh`,
    zIndex: 0,
  },
  rightButton: {
    marginRight: theme.spacing(2),
  },
  videoContainer: {
    margin: '45px 0 20px',
    '@media (max-width: 767px)': {
      display: 'none'
    }
  },
  responsiveVideo: {
    padding: "56.25% 0 0 0",
    position: "relative"
  },
  video: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    border: '10px solid #FFF'
  }
}));

export default function Landing({ children }) {
  const c = useStyles(!!children);

  return (
    <>
      <div className={c.background} />
      {!children && (
        <div className={c.buttons}>
          <Button
            className={c.rightButton}
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="/login"
          >
            Login
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="/signup"
          >
            Sign Up
          </Button>
        </div>
      )}
      <div className={c.leftColumnWrapper}>
        <div className={c.leftColumn}>
          <div className={c.logoWrapper}>
            <Link to="/">
            <img src={logo} className={c.logo} alt="logo" />
            </Link>
            <Typography variant="h5" className={c.subtitle}>
              Sample Quickly, License Instantly
            </Typography>
          </div>
          <div className={c.videoContainer}>
            <div
              className={c.responsiveVideo}
            >
              <div className={c.video}>
                <VideoPlayer src="https://amplify-ipresario-prod-151937-deployment.s3.eu-central-1.amazonaws.com/explainer.mp4" width={640} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={c.authContainer}>{children}</div>
    </>
  );
}
