import { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { PlayIcon } from "./PlayIcon";

const useStyles = makeStyles(() => ({
  video: {
    width: "100%",
    height: "100%",
  },
  container: {
    position: "relative",
  },
  play: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    border: 0,
    background: "transparent",
    opacity: 0.7,
    fontSize: '60px',
    color: '#FFF',
    cursor: 'pointer',
    '@media (min-width: 1024px)': {
      fontSize: '90px',
    }
  }
}));
export default function Player({ className, ...attrs }) {
  const [hasPlayed, setHasPlayed] = useState(false);
  const ref = useRef(null);
  const c = useStyles();
  const play = () => {
    if (ref.current) {
      ref.current.play();
      setHasPlayed(true);
    }
  };
  return (
    <div className={clsx(c.video, c.container, className)}>
      {!hasPlayed && (
        <button className={c.play} onClick={play}>
          <PlayIcon />
        </button>
      )}
      <video {...attrs} controls={hasPlayed} ref={ref} className={c.video} />
    </div>
  );
}
