import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useRef } from 'react'
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const useStyles = makeStyles((theme) => ({
  navigation: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  }
}))


export default function ScriptPreview ({ link }) {
  const [numberOfPages, setNumberOfPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const c = useStyles()
  const documentWrapperRef = useRef()

  function onDocumentLoadSuccess ({ numPages: numberOfPages }) {
    setNumberOfPages(numberOfPages)
    setPageNumber(1)
  }

  function changePage (offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage () { changePage(-1) }
  function nextPage () { changePage(1) }

  return (
    <div ref={documentWrapperRef}>
      <Document
        file={link}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          width={documentWrapperRef.current?.getBoundingClientRect().width || undefined}
        />
      </Document>
      <div className={c.navigation}>
        <ButtonGroup color='primary'>
          <Button
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >Previous</Button>
          <Button disableRipple disabled={!numberOfPages}>
            Page {pageNumber || (numberOfPages ? 1 : '--')} of {numberOfPages || '--'}
          </Button>
          <Button
            disabled={pageNumber >= numberOfPages}
            onClick={nextPage}
          >Next</Button>
        </ButtonGroup>
      </div>
    </div>
  )
}