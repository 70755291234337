import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { User, Shows } from "../helpers/contexts";
import { types, genres, age } from "../helpers/data/showSortingOptions";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Container from "@material-ui/core/Container";
import ListSubheader from "@material-ui/core/ListSubheader";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import UserIcon from "@material-ui/icons/PermIdentity";
import UploadIcon from "@material-ui/icons/PostAdd";
import MailIcon from "@material-ui/icons/MailOutline";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import TvIcon from "@material-ui/icons/LiveTv";
import MovieIcon from "@material-ui/icons/Movie";
import { FaTheaterMasks } from "react-icons/fa";
import logo from "../images/logo.svg";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { getUserFunctionalRole } from "../helpers/users";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 116,
    verticalAlign: "bottom",
  },
  homeLink: {
    color: "white",
    textDecoration: "none",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  denseListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  backdrop: {
    backgroundColor: "rgba(224, 223, 223, 0.68)",
    position: "absolute",
    inset: 0,
    zIndex: 1,
  },
  username: {
    marginLeft: "auto",
  },
}));

const sharedTopMenu = [
  {
    title: "Account Settings",
    icon: <UserIcon />,
    path: "/account",
  },
  {
    title: "Contact",
    icon: <MailIcon />,
    path: "/contact",
  },
];

const writerTopMenu = [
  {
    title: "My Shows",
    icon: <ListAltIcon />,
    path: "/home",
  },
  {
    title: "Upload a Show",
    icon: <UploadIcon />,
    path: "/upload",
  },
  ...sharedTopMenu,
];

const producerTopMenu = [
  {
    title: "Find Shows",
    icon: <SearchIcon />,
    path: "/home",
  },
  {
    title: "My Licenses",
    icon: <ListAltIcon />,
    path: "/licenses",
  },
  ...sharedTopMenu,
];

const showTypeIcons = {
  "TV Series": <TvIcon />,
  Film: <MovieIcon />,
  Play: <FaTheaterMasks size="24px" />,
  Musical: <MusicNoteIcon />,
};

const showTypesInit = types.map((type) => ({
  title: type,
  icon: showTypeIcons[type],
  selected: false,
}));

const filtersInit = [
  {
    title: "Age Range",
    type: "age",
    items: age.map((displayValue) => ({ displayValue, checked: false })),
  },
  {
    title: "Genre",
    type: "genre",
    items: genres.map((displayValue) => ({ displayValue, checked: false })),
  },
];

export default function Layout({ children }) {
  const c = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filters, setFilters] = useState(filtersInit);
  const [showTypes, setShowTypes] = useState(showTypesInit);
  const [topMenu, setTopMenu] = useState(sharedTopMenu);

  const { user, setUser } = React.useContext(User);
  const { showFilters, setShowFilters } = React.useContext(Shows);

  const location = useLocation();
  const homePage = location.pathname === "/home";

  useEffect(() => {
    if (user) {
      const userFunctionalRole = getUserFunctionalRole(user);
      if (userFunctionalRole === "writer") {
        setTopMenu(writerTopMenu);
      } else {
        setTopMenu(producerTopMenu);
      }
    }
  }, [user]);

  useEffect(() => {
    const escFunction = (event) => {
      if (event.keyCode === 27 && !drawerOpen) handleDrawerClose();
    };

    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  async function signOut() {
    try {
      await Auth.signOut();
      setUser(null);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  function handleFilterChange(filterBy, filteredValueIndex, value) {
    const _filters = [...filters];

    const filteredIndex = _filters.findIndex((item) => item.type === filterBy);

    const filteredItems = _filters[filteredIndex].items;
    filteredItems[filteredValueIndex].checked = value;

    const filtersList = filteredItems
      .filter((item) => item.checked)
      .map((item) => item.displayValue);

    setFilters(_filters);

    setShowFilters({
      ...showFilters,
      [filterBy]: filtersList,
    });
  }

  function filterShowsByType(showType) {
    const _showTypes = showTypes.map((type) => {
      let _type = { ...type };
      _type.selected = type.title === showType ? !type.selected : false;
      return _type;
    });
    setShowTypes(_showTypes);

    const _showFilters = { ...showFilters };
    _showFilters.type = showFilters.type.includes(showType) ? [] : [showType];
    setShowFilters(_showFilters);
  }

  return (
    <div>
      <AppBar
        position="fixed"
        className={clsx(c.appBar, {
          [c.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          {!!user && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(c.menuButton, drawerOpen && c.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Link to="/home" className={c.homeLink}>
            <img className={c.logo} src={logo} alt="logo" />
          </Link>
          <Typography variant="h6" className={c.username}>
            Welcome {user ? user.name : ""}
          </Typography>
        </Toolbar>
      </AppBar>
      {!!user && (
        <>
          <Drawer
            className={c.drawer}
            variant="persistent"
            anchor="left"
            open={drawerOpen}
            classes={{ paper: c.drawerPaper }}
          >
            <div className={c.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              {topMenu.map(({ title, icon, path }) => (
                <ListItem
                  button
                  key={path}
                  component={Link}
                  to={path}
                  onClick={handleDrawerClose}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={title} />
                </ListItem>
              ))}
            </List>

            {homePage && (
              <>
                <Divider />

                <List subheader={<ListSubheader>Type Of Show</ListSubheader>}>
                  {showTypes.map(({ title, icon, selected }, index) => (
                    <ListItem
                      button
                      key={index}
                      selected={selected}
                      onClick={() => {
                        filterShowsByType(title);
                      }}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={title} />
                    </ListItem>
                  ))}
                </List>

                <Divider />

                {filters.map(({ title, type, items }) => (
                  <List
                    key={type}
                    subheader={<ListSubheader>{title}</ListSubheader>}
                  >
                    {items.map(({ displayValue, checked }, index) => (
                      <ListItem classes={{ root: c.denseListItem }} key={index}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked}
                            onChange={() => {
                              handleFilterChange(type, index, !checked);
                            }}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={displayValue} />
                      </ListItem>
                    ))}
                  </List>
                ))}
              </>
            )}

            <Divider />

            <List>
              <ListItem button onClick={signOut}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Drawer>
          {drawerOpen && (
            <div className={c.backdrop} onClick={handleDrawerClose}></div>
          )}
        </>
      )}
      <main
        className={clsx(c.content, {
          [c.contentShift]: drawerOpen,
        })}
      >
        <div className={c.drawerHeader} />
        <Container maxWidth="md">{children}</Container>
      </main>
    </div>
  );
}
