import {
  useState, useCallback, useMemo,
} from 'react';

export default function useAsyncRequest({ initialIsLoading = false } = {}) {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const errorMessage = useMemo(() => {
    return error?.message
  }, [error])

  const invoke = useCallback(async (fn) => {
    try {
      setIsLoading(true);
      setError(null);
      const fnData = await fn();
      setData(fnData);
      return fnData;
    } catch (e) {
      const normalizedError = {
        message: e.message,
        code: e.status,
        originalError: e,
      };
      setError(normalizedError);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);
  const makeAsyncHandler = useCallback(
    (fn) => (...args) => invoke(fn.bind(null, ...args)),
    [invoke],
  );
  return {
    error,
    errorMessage,
    isLoading,
    data,
    setError,
    invoke,
    makeAsyncHandler,
  };
}
