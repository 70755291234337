import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles'
import Response from '../components/Response'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import SyncIcon from '@material-ui/icons/Sync'


const useStyles = makeStyles(theme => ({
  uploadingIcon: {
    animation: 'rotating 2s linear infinite',
  },
  loaderIcon: {
    marginLeft: theme.spacing(1),
    lineHeight: 1,
    transform: 'scaleX(-1)'
  },
  formControl: {
    minWidth: theme.spacing(16)
  },
  button: {
    margin: theme.spacing(2, 0, 1)
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  }
}))


export default function ChangePassword () {
  const c = useStyles()
  const [response, setResponse] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [form, setForm] = useState({
    oldPassword: '',
    newPassword: ''
  })


  const handleChange = event => {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }

  const handleChangePassword = async event => {
    event.preventDefault()
    setResponse(null)
    setSubmitting(true)

    const { oldPassword, newPassword } = form

    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
      setResponse({
        type: 'success',
        text: 'Password changed successfully'
      })
    }

    catch (error) {
      setSubmitting(false)
      setResponse({
        type: 'error',
        text: error.message
      })
    }

    finally {
      setSubmitting(false)
    }
  }


  return (
    <>
      <Typography variant='h5' className={c.title}>
        Change Password
      </Typography>
      <form onSubmit={handleChangePassword}>
        <TextField
          required
          fullWidth
          label='Old Password'
          type='password'
          name='oldPassword'
          value={form.oldPassword}
          onChange={handleChange}
          margin='normal'
          variant='outlined'
        />
        <TextField
          required
          fullWidth
          label='New Password'
          type='password'
          name='newPassword'
          value={form.newPassword}
          onChange={handleChange}
          margin='normal'
          variant='outlined'
        />
        <Button
          disabled={submitting}
          fullWidth
          className={c.button}
          type='submit'
          variant='contained'
          color='primary'
          size='large'
        >
          Submit
          {submitting && <span className={c.loaderIcon}>
            <SyncIcon className={c.uploadingIcon}/>
          </span>}
        </Button>
      </form>

      {response && <Response response={response} closeAlert={() => {setResponse(null)}}/>}
    </>
  )
}
