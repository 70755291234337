import React from 'react'
import NumberFormat from 'react-number-format'
import InfoTooltip from '../../components/InfoTooltip'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'


const LICENSE_TEXT =
  `Is your cast and creative team being paid?
  If yes, then you need a professional license.
  If no, then you need an amateur license
  Every amateur license requires you to state on all promotional material:
  "This amateur production is produced by permission of iPresario."`

const MEDIA_LICENSING_TEXT = 'If you are intending to create promotional videos, take photographs and/or record your production, you will require a media license.'


function NumberFormatCustom (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      isNumericString
      prefix='£'
    />
  )
}

export default function GetLicense ({
  show,
  handleChange,
  handleCheckboxChange,
  licenseForm,
  isTheaterProduction
}) {

  function dateChange () {
    return licenseForm.lastDate <= licenseForm.firstDate
  }


  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h6' margin='normal'>
          Select your license options
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FormControl required component='fieldset'>
          <FormLabel component='legend'>License Type</FormLabel>
          <RadioGroup
            row
            name='type'
            value={licenseForm.type}
            onChange={handleChange}
          >
            {show.licenseType.map((type, index) =>
              <FormControlLabel key={index} value={type} control={<Radio/>} label={type}/>
            )}
            <InfoTooltip title={LICENSE_TEXT}/>
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          margin='normal'
          type='date'
          variant='outlined'
          name='firstDate'
          label='First performance date'
          value={licenseForm.firstDate}
          onChange={handleChange}
        />
        {dateChange() ?
          <TextField
            fullWidth
            required
            error
            id='outlined-error'
            margin='normal'
            type='date'
            variant='outlined'
            name='lastDate'
            label='Error'
            value={licenseForm.lastDate}
            helperText='Last performance date must come after the first.'
            onChange={handleChange}
          /> :
          <TextField
            fullWidth
            required
            margin='normal'
            type='date'
            variant='outlined'
            name='lastDate'
            label='Last performance date'
            value={licenseForm.lastDate}
            onChange={handleChange}
          />}
        <TextField
          fullWidth
          required
          margin='normal'
          type='number'
          variant='outlined'
          name='numberOfScripts'
          label='Number of scripts'
          value={licenseForm.numberOfScripts}
          onChange={handleChange}
        />
        { isTheaterProduction() &&
          <>
            <TextField
              fullWidth
              required
              margin='normal'
              type='number'
              variant='outlined'
              name='performanceCount'
              label='Total number of performances'
              value={licenseForm.performanceCount}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              margin='normal'
              type='number'
              variant='outlined'
              name='venueCapacity'
              label='Venue capacity'
              value={licenseForm.venueCapacity}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              margin='normal'
              variant='outlined'
              label='Price of highest value ticket'
              InputProps={{
                inputComponent: NumberFormatCustom,
                value: licenseForm.ticketPrice,
                onChange: handleChange,
                name: 'ticketPrice'
              }}
            />
          </>
        }
      </Grid>

      <Grid item xs={12}>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Media Licensing</FormLabel>
          <RadioGroup
            row
            name='mediaLicensing'
            value={licenseForm.mediaLicensing}
            onChange={handleChange}
          >
            <FormControlLabel value='Yes' control={<Radio/>} label='Yes'/>
            <FormControlLabel value='No' control={<Radio/>} label='No'/>
            <InfoTooltip title={MEDIA_LICENSING_TEXT}/>
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormGroup>
          <FormLabel component='legend'>Select any add-on services</FormLabel>
          {licenseForm.addOnServices.map(({ name, value }) =>
            <FormControlLabel
              key={name}
              control={
                <Checkbox
                  checked={value}
                  onChange={handleCheckboxChange}
                  name={name}
                />
              }
              label={name}
            />
          )}
        </FormGroup>
      </Grid>
    </>
  )
}
