/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://qej5pldoi5hszgmh53dlvkyf3q.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6c6pewatnrgrthkx63c53cd2ya",
    "aws_cloud_logic_custom": [
        {
            "name": "payments",
            "endpoint": "https://t4u6202cf0.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "webhooks",
            "endpoint": "https://5ah2wn5gf2.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:f8e465fa-21a1-4932-8f92-b0c572441a37",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_uFZcw5wMi",
    "aws_user_pools_web_client_id": "3voq982ot4l1343b69b7gftj33",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ipresario5171958be3a94b6ab0475339192d909b151937-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
