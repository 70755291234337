import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles((theme) => ({
  totalSum: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 4)
  },
  priceTitle: {
    margin: theme.spacing(-0.5, 0, 1)
  },
  weight: {
    fontWeight: 700
  }
}))
  
export default function LicensePrice ({ prices, isTheaterProduction }) {

  const c = useStyles()

  const { totalPrice, basePrice, addOnServicesPrice, mediaLicensingPrice, scriptsPrice, royaltySubtotal } = prices


  return (
    <Paper variant='outlined' className={c.totalSum}>
      <Typography variant='overline'>Base Price</Typography>
      <Typography className={c.priceTitle} variant='subtitle1'>£{basePrice}</Typography>
      <Typography variant='overline'>Scripts Price</Typography>
      <Typography className={c.priceTitle} variant='subtitle1'>£{scriptsPrice}</Typography>
      <Typography variant='overline'>Media Price</Typography>
      <Typography className={c.priceTitle} variant='subtitle1'>£{mediaLicensingPrice}</Typography>
      <Typography variant='overline'>Royalties Subtotal</Typography>
      <Typography className={c.priceTitle} variant='subtitle1'>£{royaltySubtotal}</Typography>    
      <Typography variant='overline'>Add on services</Typography>
      <Typography className={c.priceTitle} variant='subtitle1'>£{addOnServicesPrice}</Typography>
      <Typography className={c.weight} variant='overline'>Total</Typography>
      <Typography className={c.priceTitle} variant='h6'>£{totalPrice}</Typography>
    </Paper>
  )
}