import { createMuiTheme, CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core'
import { jss } from 'react-jss'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import BeforeAuth from './pages/BeforeAuth'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'


Amplify.configure(awsconfig)
const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY)


const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Rubik"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  palette: {
    primary: {
      main: '#262f40'
    },
    secondary: {
      main: '#1070b7'
    }
  }
})


export default function App () {
  return (
    <Elements stripe={promise}>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <BeforeAuth/>
        </ThemeProvider>
      </StylesProvider>
    </Elements>
  )
}
